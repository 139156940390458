import { Navigate } from 'react-router-dom';

import useCurrentUser from '../hooks/useCurrentUser';
import checkAdminsRequirements from './helpers/checkAdminsRequirements.helper';
import checkAuthorizedRequirements from './helpers/checkAuthorizedRequirements.helper';
import checkNonAuthorizedRequirements from './helpers/checkNonAuthorizedRequirements.helper';
import checkSuperAdminRequirements from './helpers/checkSuperAdminRequirements.helper';
import isNotAllowedNavigateToAdmin from './helpers/isNotAllowedNavigateToAdmin.helper';

export const ProtectedRoute = ({
  rule,
  children,
  anyAdminOnly,
  organizationAdminOnly,
  hideForCorporativeAdmin,
  hideForRegularCorporativeUser,
}) => {
  const token = localStorage.getItem('token');
  const { currentUser, isCorporative, isCorporativeAdmin } = useCurrentUser();

  if (checkAuthorizedRequirements(rule, token)) {
    return <Navigate replace to="/login" />;
  }
  if (checkNonAuthorizedRequirements(rule, token)) {
    return <Navigate replace to="/index/survey" />;
  }
  if (checkAdminsRequirements(rule, token, currentUser)) {
    return <Navigate replace to="/index/survey" />;
  }
  if (checkSuperAdminRequirements(rule, token, currentUser)) {
    return <Navigate replace to="/index/survey" />;
  }
  if (anyAdminOnly) {
    if (!isCorporativeAdmin && isNotAllowedNavigateToAdmin(currentUser, token))
      return <Navigate replace to="/index/survey" />;
  }
  if (organizationAdminOnly && (!isCorporative || !isCorporativeAdmin)) {
    return <Navigate replace to="/index/survey" />;
  }
  if (hideForCorporativeAdmin && isCorporative && isCorporativeAdmin) {
    return <Navigate replace to="/index/employee-list" />;
  }
  if (hideForRegularCorporativeUser && isCorporative && !isCorporativeAdmin) {
    return <Navigate replace to="/index/survey" />;
  }

  return children;
};

export default ProtectedRoute;

import { NavLink } from 'react-router-dom';

const NavTab = ({ path, children, className }) => {
  return (
    <NavLink to={path} className={({ isActive }) => `${getClasses(isActive)} ${className}`}>
      {children}
    </NavLink>
  );
};

export default NavTab;

const getClasses = isActive =>
  `${
    isActive ? getActiveClasses() : getOriginalClasses()
  } h-[40px] box-border text-l text-center font-inter flex flex-col justify-center items-center cursor-pointer`;

const getActiveClasses = () => 'text-main-blue border-b-[2px] border-solid border-main-blue';

const getOriginalClasses = () => 'text-main-black';

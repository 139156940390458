import { useEffect, useState } from 'react';

import onSelectChange from '../../helpers/onSelectChange.helper';
import { idMapper, labelRelationMapper } from '../../helpers/selectMappers.helper';
import APIService from '../../services/API.service';
import useAppStore from '../../store';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';
import CulturlabsSelect from '../atoms/CulturlabsSelect/CulturlabsSelect';
import callOrgItems from '../helpers/callOrgItems.helper';
import callOrganizations from '../helpers/callOrganizations.helper';

const StatisticsSelectionControls = () => {
  const selectionFiltersStore = useAppStore(state => state.selectionFilters);
  const setSelectionFilters = useAppStore(state => state.setSelectionFilters);
  const isFiltersEnabled = Object.values(selectionFiltersStore).filter(value => value != '-')?.length > 0;

  const [currentDepartments, setCurrentDepartments] = useState([]);
  const [teams, setTeams] = useState([]);
  const [currentTeams, setCurrentTeams] = useState([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const organization = await callOrganizations();
    const departments = await callDepartments(organization.id);
  };

  const onDepartmentSelected = (selectionFiltersStore, selectedDepartment) => {
    selectionFiltersStore['team'] = null;
    const teamsOfDepartment = currentDepartments.find(dept => dept.id == selectedDepartment)?.teams;

    setCurrentTeams(teamsOfDepartment);
    setTeams(teamsOfDepartment);
  };

  const callDepartments = async orgId => {
    const departments = await callOrgItems(`${APIService.urls.departments}?byOrganization=${orgId}`);
    setCurrentDepartments(departments);
  };

  return (
    <div className="flex flex-row max-w-[800px] items-end mb-[16px]">
      <CulturlabsButton
        className="mr-[10px]"
        secondary={!isFiltersEnabled}
        onClick={() => setSelectionFilters({ department: '-', team: '-' })}
      >
        <span>Reset</span>
      </CulturlabsButton>
      <CulturlabsSelect
        className="grow mr-[10px]"
        label="Department"
        initValue={selectionFiltersStore['department']}
        listItems={currentDepartments}
        idMapper={idMapper}
        labelMapper={labelRelationMapper}
        onChange={(type, event) =>
          onSelectChange(type, event, setSelectionFilters, selectionFiltersStore, onDepartmentSelected)
        }
      />
      <CulturlabsSelect
        className="grow"
        label="Team"
        disabled={currentTeams?.length > 0 ? false : true}
        initValue={selectionFiltersStore['team']}
        listItems={currentTeams}
        idMapper={idMapper}
        labelMapper={labelRelationMapper}
        onChange={(type, event) => onSelectChange(type, event, setSelectionFilters, selectionFiltersStore)}
      />
    </div>
  );
};

export default StatisticsSelectionControls;

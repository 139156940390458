import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';

import getAuthHeader from '../../helpers/getAuthHeader';
import { isEmailValid } from '../../helpers/validators';
import APIService from '../../services/API.service';
import Loading from '../Loading/Loading';
import PasswordChangeModal from '../PasswordChangeModal/PasswordChangeModal';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';
import CulturlabsInput from '../atoms/CulturlabsInput/CulturlabsInput';

const SettingsPage = () => {
  const [loading, setLoading] = useState(false);
  const [editUserInitialForm, setEditUserInitialForm] = useState({
    name: '',
    email: '',
  });
  const [editUserForm, setEditUserForm] = useState({
    name: {
      value: '',
      errorMessage: '',
    },
    email: {
      value: '',
      errorMessage: '',
    },
  });
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const currentUserId = JSON.parse(localStorage.getItem('me')).id;

  useEffect(() => {
    fetchUserInitialData();
  }, [currentUserId]);

  const isUserDataSame = useMemo(
    () =>
      editUserForm.name.value === editUserInitialForm.name && editUserForm.email.value === editUserInitialForm.email,
    [editUserForm, editUserInitialForm],
  );

  const fetchUserInitialData = async () => {
    try {
      setLoading(true);
      const {
        data: {
          data: { name: initialName, email: initialEmail },
        },
      } = await APIService.call({
        method: 'get',
        url: `${APIService.urls.users}/${currentUserId}`,
        headers: getAuthHeader(),
      });

      setEditUserForm(({ name, email }) => ({
        name: {
          ...name,
          value: initialName,
          errorMessage: '',
        },
        email: {
          ...email,
          value: initialEmail,
          errorMessage: '',
        },
      }));
      setEditUserInitialForm({ name: initialName, email: initialEmail });
    } catch {
      // TODO: Handle error accordingly (e.g., show notification)
    } finally {
      setLoading(false);
    }
  };

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case 'name':
        return value?.length >= 2 && value?.length <= 50 ? '' : 'Please enter a valid name.';
      case 'email':
        return isEmailValid(value) ? '' : 'Please enter a valid email address.';
      default:
        return '';
    }
  };

  const handleInputChange = (fieldName, event) => {
    const trimmedValue = event.target.value.trim();
    const errorMessage = validateField(fieldName, trimmedValue);

    setEditUserForm(prev => ({
      ...prev,
      [fieldName]: {
        value: trimmedValue,
        errorMessage,
      },
    }));
  };

  const handleSubmit = async (event, data) => {
    event.preventDefault();
    setLoading(true);

    try {
      await APIService.call({
        method: 'put',
        url: `${APIService.urls.users}/${currentUserId}`,
        data,
        headers: getAuthHeader(),
      });
    } catch (error) {
      const { details, message } = error.response.data.error;

      if (details.fieldName in editUserForm)
        setEditUserForm(prev => ({
          ...prev,
          [details.fieldName]: {
            value: prev[details.fieldName].value,
            errorMessage: message,
          },
        }));
    } finally {
      setEditUserInitialForm({ name: editUserForm.name.value, email: editUserForm.email.value });
      setLoading(false);
    }
  };

  const handleChangePasswordApply = async data => {
    try {
      await APIService.call({
        method: 'post',
        url: APIService.urls.changePassword,
        data,
        headers: getAuthHeader(),
      });

      setShowPasswordModal(false);
    } catch (error) {
      return error;
    }
  };

  return (
    <div className="w-full h-[calc(100vh-90px)] p-[48px] overflow-y-auto">
      <h1 className="text-main-black text-xxl font-lato font-bold">Settings</h1>

      <Loading loading={loading}>
        <div className="max-w-[340px]">
          <form
            className="mt-[32px]"
            onSubmit={event => handleSubmit(event, { name: editUserForm.name.value, email: editUserForm.email.value })}
            noValidate
          >
            <CulturlabsInput
              label="full name"
              name="name"
              initValue={editUserInitialForm.name}
              invalid={!!editUserForm.name.errorMessage}
              handleChange={changeEvent => handleInputChange('name', changeEvent)}
            />
            <p
              className={classNames('text-red-validation-message text-m italic mb-[6px] h-[24px]', {
                invisible: !editUserForm.name.errorMessage,
              })}
            >
              {editUserForm.name.errorMessage}
            </p>
            <CulturlabsInput
              label="email"
              name="email"
              initValue={editUserInitialForm.email}
              invalid={!!editUserForm.email.errorMessage}
              handleChange={changeEvent => handleInputChange('email', changeEvent)}
            />
            <p
              className={classNames('text-red-validation-message text-m italic mb-[6px] h-[24px]', {
                invisible: !editUserForm.email.errorMessage,
              })}
            >
              {editUserForm.email.errorMessage}
            </p>

            <CulturlabsButton
              type="submit"
              className="mt-[12px] w-full"
              disabled={isUserDataSame || !!editUserForm.name.errorMessage || !!editUserForm.email.errorMessage}
            >
              Save Changes
            </CulturlabsButton>
          </form>

          <CulturlabsButton type="button" className="mt-[24px] w-full" onClick={() => setShowPasswordModal(true)}>
            Update Password
          </CulturlabsButton>
        </div>
      </Loading>

      {showPasswordModal && (
        <PasswordChangeModal onApply={handleChangePasswordApply} closeModal={() => setShowPasswordModal(false)} />
      )}
    </div>
  );
};

export default SettingsPage;

import emptyArrowFunc from './emptyArrowFunc.helper';
import unwrapValueFromInputChangeEvent from './unwrapValueFromInputChangeEvent.helper';

const onSelectChange = async (type, originalEvent, setChanges, changes, onChange = emptyArrowFunc) => {
  const selected = unwrapValueFromInputChangeEvent(originalEvent) || originalEvent;
  const formUpdate = {
    ...changes,
  };
  onChange(formUpdate, selected);
  formUpdate[type.toLocaleLowerCase()] = selected || undefined;
  setChanges(formUpdate);
};

export default onSelectChange;

import { Navigate, Route, Routes } from 'react-router-dom';

import AuthScreen from '../components/AuthScreen/AuthScreen';
import ContactUsPage from '../components/ContactUsPage/ContactUsPage';
import CsvUploadPage from '../components/CsvUploadPage/CsvUploadPage';
import EmptyStatePage from '../components/EmptyStatePage/EmptyStatePage';
import InterventionCataloguePage from '../components/InterventionCataloguePage/InterventionCataloguePage';
import ListOfEmployeesPage from '../components/ListOfEmployeesPage/ListOfEmployeesPage';
import MainAppContainer from '../components/MainAppContainer/MainAppContainer';
import RateSurveyPage from '../components/RateSurveyPage/RateSurveyPage';
import SettingsPage from '../components/SettingsPage/SettingsPage';
import SubFactorsPage from '../components/SubFactorsPage/SubFactorsPage';
import SurveyPageComponent from '../components/SurveyPageComponent/SurveyPageComponent';
import TemperatureFactorsPage from '../components/TemperatureFactorsPage/TemperatureFactorsPage';
import TemperatureStatisticsPage from '../components/TemperatureStatisticsPage/TemperatureStatisticsPage';
import {
  AssignSurvey as AssignSurveyPage,
  EmployeeList as EmployeeListPage,
  ExportResults as ExportResultsPage,
  HashAuthorization as HashAuthorizationPage,
  LeaveFeedback as LeaveFeedbackPage,
  NotFound as NotFoundPage,
} from '../pages';
import useAppStore from '../store';
import ProtectedRoute from './ProtectedRoute';

const Router = () => {
  const { jwt } = useAppStore(state => state.auth) || localStorage.getItem('token');

  return (
    <Routes>
      <Route path="/" element={!jwt ? <Navigate to="/login" /> : <></>} />
      <Route
        path="/login"
        element={
          <ProtectedRoute rule="forNonAuthOnly">
            <AuthScreen authType={'login'} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/registration"
        element={
          <ProtectedRoute rule="forNonAuthOnly">
            <AuthScreen authType={'registration'} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <ProtectedRoute rule="forNonAuthOnly">
            <AuthScreen authType={'forgotPassword'} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/confirm-email"
        element={
          <ProtectedRoute rule="forNonAuthOnly">
            <div className="m-[48px]">
              <EmptyStatePage type="confirmEmail" />
            </div>
          </ProtectedRoute>
        }
      />
      <Route
        path="/reset-password"
        element={
          <ProtectedRoute rule="forNonAuthOnly">
            <div className="m-[48px]">
              <EmptyStatePage type="resetPassword" />
            </div>
          </ProtectedRoute>
        }
      />
      <Route
        path="/index"
        element={
          <ProtectedRoute rule="forAuthOnly">
            <MainAppContainer />
          </ProtectedRoute>
        }
      >
        <Route
          path="/index/survey"
          element={
            <ProtectedRoute rule="forAuthOnly" hideForCorporativeAdmin>
              <SurveyPageComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="leave-feedback"
          element={
            <ProtectedRoute rule="forAuthOnly">
              <LeaveFeedbackPage />
            </ProtectedRoute>
          }
        />
        <Route path="/index/temperature" element={<Navigate to="/index/temperature/overview" />} />
        <Route
          path="/index/temperature/overview"
          element={
            <ProtectedRoute rule="forAuthOnly" anyAdminOnly>
              <TemperatureFactorsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/index/temperature/statistics"
          element={
            <ProtectedRoute rule="forAuthOnly" anyAdminOnly>
              <TemperatureStatisticsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/index/temperature/:factor"
          element={
            <ProtectedRoute rule="forAuthOnly" anyAdminOnly>
              <SubFactorsPage />
            </ProtectedRoute>
          }
        />
        <Route path="organizations">
          <Route path="temperature">
            <Route
              path="overview"
              element={
                <ProtectedRoute rule="forAuthOnly" organizationAdminOnly>
                  <TemperatureFactorsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="statistics"
              element={
                <ProtectedRoute rule="forAuthOnly" organizationAdminOnly>
                  <TemperatureStatisticsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path=":factor"
              element={
                <ProtectedRoute rule="forAuthOnly" organizationAdminOnly>
                  <SubFactorsPage />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
        <Route
          path="/index/interventions"
          element={
            <ProtectedRoute rule="forAuthOnly" anyAdminOnly>
              <InterventionCataloguePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/index/employee-list"
          element={
            <ProtectedRoute rule="forAuthOnly" organizationAdminOnly>
              <EmployeeListPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/index/list-of-employees"
          element={
            <ProtectedRoute rule="forAdminOnly">
              <ListOfEmployeesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/index/assign-survey"
          element={
            <ProtectedRoute rule="forAuthOnly" organizationAdminOnly>
              <AssignSurveyPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/index/export-results"
          element={
            <ProtectedRoute rule="forAuthOnly" organizationAdminOnly>
              <ExportResultsPage />
            </ProtectedRoute>
          }
        />
        <Route path="/index/contact-us" element={<ContactUsPage />} />
        <Route
          path="/index/user-settings"
          element={
            <ProtectedRoute rule="forAuthOnly" hideForRegularCorporativeUser>
              <SettingsPage />
            </ProtectedRoute>
          }
        />
        <Route path="/index/rate-survey" element={<RateSurveyPage />} />
      </Route>
      <Route
        path="/upload-csv"
        element={
          <ProtectedRoute rule="forSuperAdminOnly">
            <div className="m-[48px]">
              <CsvUploadPage />
            </div>
          </ProtectedRoute>
        }
      />
      <Route path="organizations">
        <Route path=":organization">
          <Route index element={<HashAuthorizationPage />} />
        </Route>
        <Route index element={<Navigate replace to="/404" />} />
      </Route>
      <Route path="404" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Router;

import { useNavigate } from 'react-router-dom';

import { ReactComponent as MagnifierSVG } from '../../assets/temperature/search-magnifier-sad.svg';
import useCurrentUser from '../../hooks/useCurrentUser';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';

const NoResultsBlock = () => {
  const navigate = useNavigate();
  const { isCorporativeAdmin } = useCurrentUser();

  return (
    <div className="flex flex-col items-center gap-12">
      <MagnifierSVG />
      <p className="font-bold text-center text-l">It looks like we don't have any results to show you at the moment.</p>
      {isCorporativeAdmin && (
        <div className="flex flex-col md:flex-row w-1/2 gap-4 justify-evenly">
          <CulturlabsButton className={'leading-[1] min-w-[180px]'} onClick={() => navigate('/index/assign-survey')}>
            Assign a Survey
          </CulturlabsButton>
          <CulturlabsButton className={'leading-[1] min-w-[180px]'} onClick={() => navigate('/index/employee-list')}>
            Track Engagement
          </CulturlabsButton>
        </div>
      )}
    </div>
  );
};

export default NoResultsBlock;

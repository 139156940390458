import { ReactComponent as CloseSVG } from '../../assets/close.svg';
import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';

const TermsAndConditionsModal = ({ closeModal = emptyArrowFunc }) => {
  const download = pdfFileName => {
    const pdfUrl = `${process.env.REACT_APP_PATH_TO_FILES}/${pdfFileName}`;
    fetch(pdfUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = pdfFileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error downloading PDF:', error);
      });
  };

  return (
    <div className="absolute left-[50px] -m-[48px] top-[30px] z-50 w-[100vw] h-[100vh] flex flex-col justify-center items-center">
      <div className="w-[100vw] h-[100vh] bg-black opacity-50"></div>
      <div className="absolute flex flex-col w-[400px] h-[500px] rounded-[24px] p-[32px] bg-lightgray">
        <header className="flex flex-row mb-[32px]">
          <p className="grow text-semi-xl font-bold">Terms and Conditions</p>{' '}
          <CloseSVG className="[&>*]:fill-main-black w-[24px] cursor-pointer" onClick={() => closeModal()} />
        </header>
        <div className="flex flex-col justify-center items-center">
          You can download Privacy Policy and Terms and Conditions. Download as PDF file.
        </div>
        <CulturlabsButton
          type="button"
          className="w-full mt-auto"
          onClick={() => download('TEMPERATURE_Privacy_Policy.pdf')}
        >
          Privacy Policy
        </CulturlabsButton>

        <CulturlabsButton
          type="button"
          className="w-full mt-[30px]"
          onClick={() => download('TEMPERATURE_Terms_and_Conditions.pdf')}
        >
          Terms and Conditions
        </CulturlabsButton>
      </div>
    </div>
  );
};
export default TermsAndConditionsModal;

import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

import { ReactComponent as FlameSVG } from '../../assets/temperature/flame.svg';
import { ReactComponent as SnowflakeSVG } from '../../assets/temperature/snowflake.svg';
import RoundedIcon from '../RoundedIcon/RoundedIcon';
import AnswerRangeSlider from '../atoms/AnswerRangeSlider/AnswerRangeSlider';

const PolarAnswer = ({ onChange, isTouched, onTouch, value, question }) => (
  <section className="flex flex-col">
    <div className="flex flex-row justify-center content-center">
      <div className="w-[20%]"></div>
      <div className="flex flew-row justify-between grow m-[5px]">
        <SnowflakeSVG width={'40px'} height={'40px'} />
        <RoundedIcon iconColor={'white'} faIcon={faCheckCircle} backgroundColor="bg-lightgreen" />
        <FlameSVG width={'40px'} height={'40px'} />
      </div>
      <div className="w-[20%]"></div>
    </div>

    <div className="flex flex-row justify-center content-center h-[148px] w-full">
      <div
        className="flex flex-col p-[20px] items-center justify-center w-[20%] bg-white rounded-l-[20px] bg-white"
        style={{ fontSize: '20px' }}
      >
        {question?.cold}
      </div>
      <div className="flex flex-col justify-center p-[10px] grow bg-white mx-[5px] bg-white ">
        <AnswerRangeSlider
          initValue={value}
          isTouched={isTouched}
          onChange={event => onChange(event)}
          onTouch={onTouch}
        />
      </div>
      <div
        className="flex flex-col p-[20px] items-center justify-center w-[20%] bg-white rounded-r-[20px]  bg-white"
        style={{ fontSize: '20px' }}
      >
        {question?.hot}
      </div>
    </div>
  </section>
);

export default PolarAnswer;

import { useEffect, useState } from 'react';

import { ReactComponent as CloseSVG } from '../../assets/close.svg';
import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import isSelected from '../../helpers/isSelected';
import unwrapValueFromInputChangeEvent from '../../helpers/unwrapValueFromInputChangeEvent.helper';
import { isValidEmail } from '../../helpers/validationEmail';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';
import CulturlabsInput from '../atoms/CulturlabsInput/CulturlabsInput';
import CulturlabsSelect from '../atoms/CulturlabsSelect/CulturlabsSelect';

const InviteUserModal = ({ onInvite = emptyArrowFunc, closeModal = emptyArrowFunc }) => {
  const [controlsState, setControlsState] = useState({});
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    setFormValid(isValidEmail(controlsState.email) && isSelected(controlsState.role));
  }, [controlsState]);

  const handleChange = (formControlName, originalEvent) => {
    const formControlUpdatedValue = unwrapValueFromInputChangeEvent(originalEvent);
    const formUpdate = {
      ...controlsState,
      [formControlName]: formControlUpdatedValue?.trim() || undefined,
    };
    setControlsState(formUpdate);
  };

  const onInviteHandler = () => {
    onInvite({ ...controlsState, email: controlsState.email?.trim() });
    closeModal();
  };

  const roles = ['Employee'];

  return (
    <div className="absolute -ml-[20px] -left-[168px] -m-[48px] -top-[0px] z-50 w-[100vw] h-[100vh] flex flex-col justify-center items-center">
      <div className="w-[100vw] h-[100vh] bg-black opacity-50"></div>
      <div className="absolute flex flex-col w-[400px] h-[500px] rounded-[24px] p-[32px] bg-lightgray">
        <header className="flex flex-row mb-[32px]">
          <p className="grow text-semi-xl font-bold">Invite User</p>{' '}
          <CloseSVG className="[&>*]:fill-main-black w-[24px] cursor-pointer" onClick={() => closeModal()} />
        </header>
        <div className="flex flex-col justify-center items-center">
          <CulturlabsInput
            label="Email"
            className="mb-[16px] w-full"
            required
            handleChange={changeEvent => handleChange('email', changeEvent)}
          />
          <CulturlabsSelect
            className="mb-[16px] w-full"
            label="Role"
            listItems={roles}
            idMapper={item => item}
            labelMapper={item => item}
            required
            onChange={(label, changeEvent) => handleChange('role', changeEvent)}
          ></CulturlabsSelect>
        </div>
        <CulturlabsButton disabled={!formValid} type="button" className="w-full mt-auto" onClick={onInviteHandler}>
          Invite
        </CulturlabsButton>
      </div>
    </div>
  );
};
export default InviteUserModal;

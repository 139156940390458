import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import CulturlabsButton from '../../components/atoms/CulturlabsButton/CulturlabsButton';
import CulturlabsSelect from '../../components/atoms/CulturlabsSelect/CulturlabsSelect';
import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import fileToBase64 from '../../helpers/fileToBase64';
import getAuthHeader from '../../helpers/getAuthHeader';
import getHighlightedText from '../../helpers/getHighlightedText';
import { idMapper, labelRelationMapper } from '../../helpers/selectMappers.helper';
import APIService from '../../services/API.service';

const HEADER_TEXT = 'Assign Survey';

const SURVEYS = [{ id: 1, name: 'default' }];
const DEFAULT_SURVEY = SURVEYS[0];

const SURVEY_TYPES = [
  { id: 'full', name: 'baseline' },
  { id: 'pulse', name: 'pulse' },
];
const DEFAULT_SURVEY_TYPE = SURVEY_TYPES[0].id;

export const AssignSurvey = () => {
  const [assignResults, setAssignResults] = useState(null);
  const [survey, setSurvey] = useState(DEFAULT_SURVEY);
  const [surveyIdOptions] = useState(SURVEYS);
  const [surveyType, setSurveyType] = useState(DEFAULT_SURVEY_TYPE);
  const [surveyTypeOptions] = useState(SURVEY_TYPES);
  const [attachedFile, setAttachedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => handleReset, []);

  const handleChangeSurveyId = event => {
    setSurvey(surveyIdOptions.find(({ id }) => String(id) === String(event.target.value)) || null);
  };

  const handleChangeSurveyType = id => {
    setSurveyType(id);
  };

  const handleAttachedFile = file => setAttachedFile(file ?? null);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const csvFileData = await fileToBase64(attachedFile);

      const {
        data: { data },
      } = await APIService.call({
        method: 'post',
        url: APIService.urls.assignedSurveyImport,
        headers: getAuthHeader(),
        data: {
          surveyType,
          surveyId: survey.id,
          csvFile: csvFileData,
        },
      });
      setAssignResults(data);
    } catch (e) {
      console.error(e);
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setAssignResults(null);
    setSurvey(DEFAULT_SURVEY);
    setSurveyType(DEFAULT_SURVEY_TYPE);
    setAttachedFile(null);
  };

  const handleResetError = () => setError(null);

  const getRenderContent = () => {
    if (error)
      return (
        <div className="p-[20px] md:p-[40px] flex flex-col gap-y-[32px]">
          {getHighlightedText(error?.response?.data?.error?.message, [
            'employee name',
            'email',
            'team',
            'department',
          ]) ?? 'Something went wrong...'}

          <CulturlabsButton
            className="ml-auto mr-0 h-[48px] bg-red-alert hover:bg-red-alert hover:opacity-90 active:bg-red-alert active:opacity-90 hover:shadow-button-alert"
            onClick={() => {
              handleReset();
              handleResetError();
            }}
          >
            <span>Close</span>
          </CulturlabsButton>
        </div>
      );

    if (assignResults)
      return (
        <div className="p-[20px] md:p-[40px] flex flex-col gap-y-[32px]">
          {assignResults.map(({ id, name, email, status }) => {
            return (
              <div key={id} className="flex justify-between">
                <div className="flex-1">
                  <p className="text-main-black text-l break-all">{name ?? '-'}</p>
                  <p className="text-main-black italic text-slate-400 break-all">{email}</p>
                </div>
                <div className="flex-1">
                  <p
                    className={classNames('text-end', {
                      'text-green-success': status.ok,
                      'text-red-failure': !status.ok,
                    })}
                  >
                    {status.ok ? 'Assigned' : 'Not assigned'}
                  </p>
                  {status.description && <p className="text-end text-slate-400">{status.description}</p>}
                </div>
              </div>
            );
          })}

          <p className="p-4 text-s text-blue-01 rounded-xl bg-indigo-100">
            <span className="pr-2">ⓘ</span> Case insensitivity has been successfully applied to your CSV file’s column
            headers and data values.
          </p>

          <CulturlabsButton
            className="mx-auto h-[48px] w-[230px] flex items-center justify-center"
            onClick={handleReset}
          >
            <span>Ok</span>
          </CulturlabsButton>
        </div>
      );

    return (
      <div
        className={classNames('shrink p-[20px] md:p-[40px] flex flex-col items-center gap-[32px]', {
          'blur-sm': loading,
        })}
      >
        <CulturlabsSelect
          required
          initValue={idMapper(DEFAULT_SURVEY)}
          className="grow w-full"
          label="Survey"
          listItems={surveyIdOptions}
          idMapper={idMapper}
          labelMapper={labelRelationMapper}
          onChange={(_, event) => handleChangeSurveyId(event)}
        />

        <fieldset className="w-full">
          <legend className="text-main-black font-semibold mb-[2px] text-s uppercase font-inter">Survey type</legend>
          <div className="flex gap-8">
            {surveyTypeOptions.map(({ id, name }) => (
              <div key={id} onClick={() => handleChangeSurveyType(id)} className="cursor-pointer">
                <input
                  type="radio"
                  id={id}
                  name={name}
                  value={id}
                  className="mr-2 accent-blue-01 cursor-pointer"
                  checked={id === surveyType}
                  onChange={emptyArrowFunc}
                />
                <label htmlFor={id} className="cursor-pointer">
                  {name}
                </label>
              </div>
            ))}
          </div>
        </fieldset>

        <div className="flex items-center justify-center w-full">
          <label
            htmlFor="dropzone-file"
            className={classNames(
              'z-10 flex flex-col items-center justify-center w-full h-48 border-2 border-dashed border-gray-01 hover:border-blue-01 hover:bg-indigo-100 rounded-lg cursor-pointer',
              { 'border-emerald-600 bg-green-100': attachedFile },
            )}
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm">
                <span className="font-semibold text-main-black">{attachedFile ? 'Uploaded' : 'Click to upload'}</span>
              </p>
              <p className="text-xs text-main-black">{attachedFile ? attachedFile.name : 'CSV'}</p>
            </div>
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              accept=".csv"
              onChange={e => handleAttachedFile(e.target.files?.[0])}
            />
          </label>
        </div>
        <CulturlabsButton
          className="mx-auto h-[48px] w-[230px] flex items-center justify-center"
          disabled={!survey || !surveyType || !attachedFile}
          onClick={handleSubmit}
        >
          <span>Submit</span>
        </CulturlabsButton>
      </div>
    );
  };

  return (
    <div className="h-screen w-full xl:min-w-[630px] p-[24px] md:p-[48px] flex flex-col items-center overflow-y-scroll">
      <h1 className="text-lato text-main-black text-xl md:text-xxl font-bold mb-[32px]">{HEADER_TEXT}</h1>

      <div className="bg-white rounded-[24px] md:min-w-[500px] relative">
        {loading && (
          <div className="absolute bg-white inset-0 z-10 rounded-[24px] opacity-50 flex justify-center items-center">
            <span className="text-lato text-xl">Loading...</span>
          </div>
        )}
        {getRenderContent()}
      </div>
    </div>
  );
};

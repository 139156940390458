import ProgressBar from '../ProgressBar/ProgressBar';

const SurveyHeader = ({ title, sumNumber, currentNumber, currentAnswer }) => {
  const isCurrentAnswerDefined = Number.isNaN(Number.parseInt(currentAnswer)) ? false : true;

  const amountOfInactive = sumNumber - currentNumber - isCurrentAnswerDefined + 1 || 0;

  const sumNumberOfPulsePortion = process.env.REACT_APP_NUMBER_OF_QUESTIONS_IN_PULSE_SURVEY;

  const inactiveBars = Array.from({ length: amountOfInactive })?.map((current, index) => {
    const className = `bg-inactive-bar flex-grow ${index < 3 ? 'mr-1' : ''}`;

    return <ProgressBar key={index} className={className} />;
  });

  const isPulseSurvey = sumNumber == sumNumberOfPulsePortion;

  const isBarActive = barNumber => barNumber < currentNumber || (barNumber === currentNumber && isCurrentAnswerDefined);

  return (
    <section className="relative rounded-[24px] h-[192px] flex flex-col justify-center items-center bg-main-black mb-[100px] 2xl:mb-[120px]">
      {isPulseSurvey && (
        <div className="pb-[16px] flex flex-row w-[400px]">
          {isBarActive(1) && <ProgressBar className="bg-first-bar flex-grow mr-1" />}
          {isBarActive(2) && <ProgressBar className="bg-second-bar flex-grow mr-1" />}
          {isBarActive(3) && <ProgressBar className="bg-third-bar flex-grow mr-1" />}
          {isBarActive(4) && <ProgressBar className="bg-fourth-bar flex-grow mr-1" />}
          {inactiveBars}
        </div>
      )}
      {!isPulseSurvey && (
        <>
          <div className="absolute p-[10px] border border-inactive-bar text-l rounded-[10px] text-inactive-bar top-[20px] right-[20px]">
            {' '}
            {currentNumber} / {sumNumber}{' '}
          </div>
        </>
      )}
      <h1 className="text-white text-xl w-[50%] text-center">{title}</h1>
    </section>
  );
};

export default SurveyHeader;

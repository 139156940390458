import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RoundedIcon = ({ backgroundColor, iconColor, faIcon }) => {
  const wrapperClassName = `${backgroundColor} flex flex-col justify-center items-center rounded-full w-[40px] h-[40px] text-[24px]`;
  return (
    <div className={wrapperClassName} style={{ backgroundColor }}>
      <FontAwesomeIcon color={iconColor} icon={faIcon} />
    </div>
  );
};

export default RoundedIcon;

import React, { useEffect, useState } from 'react';

import celebrateImage from '../../assets/images/celebrate.webp';
import checkmarkImage from '../../assets/images/checkmark.webp';
import rocketImage from '../../assets/images/rocket.webp';
import CulturlabsButton from '../../components/atoms/CulturlabsButton/CulturlabsButton';
import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import getAuthHeader from '../../helpers/getAuthHeader';
import APIService from '../../services/API.service';

const HEADER_TEXT = 'Survey Completed!';

const QUESTION_CLEARNESS_OPTIONS = ['very clear', 'clear', 'not clear'];
const OVERALL_EXPERIENCE_OPTIONS = ['excellent', 'good', 'average', 'poor'];

export const LeaveFeedback = () => {
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [questionClearness, setQuestionClearness] = useState(null);
  const [overallExperience, setOverallExperience] = useState(null);
  const [additionalComments, setAdditionalComments] = useState('');

  useEffect(() => () => setDataSubmitted(false), []);

  const handleSubmit = () => {
    setLoading(true);

    APIService.call({
      method: 'post',
      url: APIService.urls.feedback,
      data: {
        type: 'surveyCompletedFeedback',
        questionClearness,
        overallExperience,
        additionalComments,
      },
      headers: getAuthHeader(),
    })
      .then(() => {
        resetAllFields();
        setDataSubmitted(true);
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeQuestionClearness = value => setQuestionClearness(value);

  const handleChangeOverallExperience = value => setOverallExperience(value);

  const handleChangeAdditionalComments = event => setAdditionalComments(event.target.value);

  const resetAllFields = () => {
    setQuestionClearness(null);
    setOverallExperience(null);
    setAdditionalComments('');
  };

  if (dataSubmitted)
    return (
      <div className="h-screen w-full xl:min-w-[630px] p-[24px] md:p-[48px] flex flex-col items-center overflow-y-scroll">
        <h1 className="text-lato text-main-black text-xl md:text-xxl font-bold mb-[48px] text-center">
          Thanks for submitting your feedback!
        </h1>

        <div className="w-full flex flex-col gap-[48px]">
          <div className="flex flex-col gap-[48px] items-center">
            <img alt="checkmark" src={checkmarkImage} className="h-[100px]" />
            <p className="max-w-[400px] text-center">You can now close this window</p>
          </div>
        </div>
      </div>
    );

  return (
    <div className="h-screen w-full xl:min-w-[630px] p-[24px] md:p-[48px] flex flex-col items-center overflow-y-scroll">
      <div className="flex items-center mb-[48px] gap-4">
        <h1 className="text-lato text-main-black text-xl md:text-xxl font-bold">{HEADER_TEXT}</h1>
        <img alt="celebrate" className="h-[56px]" src={celebrateImage} />
      </div>

      <div className="w-full flex flex-col gap-[48px]">
        <div className="flex flex-col gap-[48px] items-center">
          <img alt="rocket" src={rocketImage} />
          <p className="max-w-[400px] text-center">
            Thank you for completing the survey. Your feedback is valuable to us.
          </p>
        </div>

        <div>
          <p>How clear were the survey questions? *</p>
          {QUESTION_CLEARNESS_OPTIONS.map(option => (
            <div key={option} onClick={() => handleChangeQuestionClearness(option)} className="cursor-pointer w-fit">
              <input
                type="radio"
                id={option}
                name={option}
                value={option}
                className="mr-2 accent-blue-01 cursor-pointer"
                checked={option === questionClearness}
                onChange={emptyArrowFunc}
              />
              <label htmlFor={option} className="cursor-pointer capitalize">
                {option}
              </label>
            </div>
          ))}
        </div>

        <div>
          <p>How was your overall experience using the platform? *</p>
          {OVERALL_EXPERIENCE_OPTIONS.map(option => (
            <div key={option} onClick={() => handleChangeOverallExperience(option)} className="cursor-pointer w-fit">
              <input
                type="radio"
                id={option}
                name={option}
                value={option}
                className="mr-2 accent-blue-01 cursor-pointer"
                checked={option === overallExperience}
                onChange={emptyArrowFunc}
              />
              <label htmlFor={option} className="cursor-pointer capitalize">
                {option}
              </label>
            </div>
          ))}
        </div>

        <div className="flex flex-col">
          <label htmlFor={'additionalComments'} className="cursor-pointer">
            Additional Comments
          </label>
          <textarea
            id={'additionalComments'}
            name={'additionalComments'}
            value={additionalComments}
            className="mt-2 border-2 bg-transparent"
            rows={3}
            onChange={handleChangeAdditionalComments}
          />
        </div>

        <CulturlabsButton
          className="mx-auto h-[48px] w-[230px] flex items-center justify-center"
          disabled={loading || !questionClearness || !overallExperience}
          onClick={handleSubmit}
        >
          <span>{loading ? 'Submitting...' : 'Submit'}</span>
        </CulturlabsButton>
      </div>
    </div>
  );
};

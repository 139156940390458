import emptyArrowFunc from '../../../helpers/emptyArrowFunc.helper';

const CulturlabsModalListItem = ({ type, label, href, onClick = emptyArrowFunc, red, disabled, secondary }) => {
  switch (type) {
    case 'link':
      return (
        <a className={`${getSharedClasses({ red, disabled, secondary })}`} href={href} onClick={onClick}>
          {label}
        </a>
      );
    case 'button':
      return (
        <button className={`${getSharedClasses({ red, disabled, secondary })}`} onClick={onClick}>
          {label}
        </button>
      );
    default:
      console.error(`Modal list item type not supported: ${type}`);
      break;
  }
};

export default CulturlabsModalListItem;

const getSharedClasses = ({ red, disabled, secondary }) => {
  const base = `bg-white text-lato text-ml `;
  const general = ' cursor-pointer text-main-black ';

  if (disabled) return base + (disabled && 'text-gray-03 cursor-not-allowed');

  if (red) return base + (red && 'text-red-list-item') + general;

  if (secondary) return base + (secondary && 'text-dark-gray') + general;

  return base + general;
};

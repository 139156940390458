import ReactApexChart from 'react-apexcharts';

const FactorCircleBar = ({ options }) => {
  const { label, value } = options;

  const state = {
    series: [value],
    options: {
      chart: {
        type: 'radialBar',
        height: '350px',
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -100,
          endAngle: 100,
          hollow: {
            margin: 0,
            size: '70%',
            position: 'front',
            dropShadow: {
              enabled: false,
            },
          },
          track: {
            background: '#E2E2E2',
            strokeWidth: '100%',
            margin: 0,
            dropShadow: {
              enabled: false,
            },
          },
          dataLabels: {
            show: false,
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          type: 'horizontal',
          stops: [0, 100],
          colorStops: getRespectiveStopsByPercent(value),
        },
      },
      stroke: {
        lineCap: 'round',
      },
      labels: [label],
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          filter: {
            type: 'none',
          },
        },
      },
    },
  };

  return <ReactApexChart onClick={null} options={state.options} series={state.series} type="radialBar" height={350} />;
};

export default FactorCircleBar;

const getRespectiveStopsByPercent = percent => {
  const colorStops = [
    {
      offset: 20,
      color: '#8CB8F6',
    },
  ];

  if (percent > 35)
    colorStops.push({
      offset: 60,
      color: '#75E662',
    });

  if (percent > 65)
    colorStops.push({
      offset: 75,
      color: '#E0F183',
    });

  if (percent > 80)
    colorStops.push({
      offset: 90,
      color: '#F9776C',
    });

  return colorStops;
};

import { useEffect } from 'react';
import { useState } from 'react';

import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import APIService from '../../services/API.service';
import EmptyStatePage from '../EmptyStatePage/EmptyStatePage';
import Loading from '../Loading/Loading';
import QuestionPage from '../QuestionPage/QuestionPage';

const SurveyComponent = ({ employeeInfo, onSend = emptyArrowFunc }) => {
  const [survey, setSurvey] = useState(null);
  const [answers, setAnswers] = useState({});
  const [currentQuestionIndex, setCurrentIndexQuestion] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isInitiated, setIsInitiated] = useState(false);

  const questions = survey?.questions || [];
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (token?.length > 1) {
      fetchCurrentSurvey();
      setIsInitiated(true);
    }

    return () => setSurvey(null);
  }, [token]);

  const fetchCurrentSurvey = async () => {
    try {
      setLoading(true);

      const response = await APIService.call({
        url: APIService.urls.assignedSurvey,
        method: 'get',
        headers: { Authorization: `Bearer ${token}` },
      });
      setSurvey(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const isFirstQuestion = currentQuestionIndex => currentQuestionIndex === 0;

  const isLastQuestion = (questions, currentQuestionIndex) => questions.length - 1 === currentQuestionIndex;

  const isEveryAnswerAccumulated = (questions, answers) => questions.length === Object.keys(answers || {}).length;

  const upsertNewAnswer = (answer, answers, currentQuestionIndex) => ({
    ...answers,
    [currentQuestionIndex]: answer,
  });

  const sendAnswer = async answer => {
    const callConfig = {
      url: APIService.urls.questionAnswer,
      method: 'post',
      headers: { Authorization: `Bearer ${token}` },
      data: answer,
    };
    const response = await APIService.call(callConfig).catch(emptyArrowFunc);
    if (response?.status !== 200) {
      return;
    }

    return response;
  };

  const onPrevious = answer => {
    setAnswers(upsertNewAnswer(answer, answers, currentQuestionIndex));
    setCurrentIndexQuestion(currentQuestionIndex - 1);
  };

  const onNext = async answer => {
    const upserted = upsertNewAnswer(answer, answers, currentQuestionIndex);
    setAnswers(upserted);
    setCurrentIndexQuestion(currentQuestionIndex + 1);
    if (currentQuestionIndex + 1 === questions?.length) {
      // TODO fix

      const requestData = {
        survey: survey.id,
        answers: upserted,
        type: survey.type,
      };
      if (employeeInfo) {
        requestData.employeeInfo = employeeInfo;
      }
      const answerResponse = (await sendAnswer(requestData))?.data;

      setSurvey(null);
      setCurrentIndexQuestion(0);
      onSend({ answerResponse });
    }
  };
  localStorage.setItem('haveSurvey', JSON.stringify(!!survey));

  // Don't show any sign before page initiation (loading)
  if (!isInitiated && !loading) return null;
  // Let the user know that data is being fetched
  if (loading) return <Loading loading={loading} />;
  // When the page is initiated (loaded) we decide what to show:
  // Empty page if no survey found, and Question page if a survey is ready to be passed
  if (!survey) return <EmptyStatePage type="noSurveys" hideLogo />;

  return (
    <QuestionPage
      questions={questions}
      currentQuestion={questions?.[currentQuestionIndex]}
      currentAnswer={answers[currentQuestionIndex]}
      onNext={onNext}
      onPrevious={onPrevious}
      isFirstQuestion={isFirstQuestion(currentQuestionIndex)}
      isLastQuestion={isLastQuestion(questions, currentQuestionIndex)}
      isEveryAnswerAccumulated={isEveryAnswerAccumulated(questions, answers)}
    />
  );
};

export default SurveyComponent;

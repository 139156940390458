import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import APIService from '../../services/API.service';
import useAppStore from '../../store';
import { EmployeeInfo } from '../../views';
import QuadrantChartSurveyResultPage from '../QuadrantChartSurveyResultPage/QuadrantChartSurveyResultPage';
import RateSurveyPage from '../RateSurveyPage/RateSurveyPage';
import SurveyComponent from '../SurveyComponent/SurveyComponent';

const GATHER_EMPLOYEE_INFO_ORGANIZATION_LIST = ['unilever'];

const SurveyPageComponent = () => {
  const [employeeInfo, setEmployeeInfo] = useState({});
  const [answers, setAnswers] = useState([]);
  const [responseOfSurvey, setResponseOfSurvey] = useState({});
  const [isQuadrantClosed, setIsQuadrantClosed] = useState();
  const token = localStorage.getItem('token');
  const onSendAnswers = ({ answerResponse: { isRateNeeded, isSurveyCompleted, allSaved } }) => {
    setAnswers(allSaved);
    setResponseOfSurvey({ isRateNeeded, isSurveyCompleted });
  };

  const navigate = useNavigate();
  const userFromStore = useAppStore(state => state.me);

  const { organization, organizationRole } = !isEmpty(userFromStore)
    ? userFromStore
    : JSON.parse(localStorage.getItem('me'));
  const shouldDisplayEmployeeInfoScreen = useMemo(
    () =>
      GATHER_EMPLOYEE_INFO_ORGANIZATION_LIST.includes(organization?.name) &&
      organizationRole !== 'admin' &&
      isEmpty(employeeInfo),
    [organization, organizationRole, employeeInfo],
  );

  const onSubmitRateSurvey = rating => {
    APIService.call({
      method: 'post',
      url: APIService.urls.rateSurveys,
      data: rating,
      headers: { Authorization: `Bearer ${token}` },
    })
      .catch(emptyArrowFunc)
      .then(response => {
        return response?.data?.data;
      });

    setAnswers([]);
  };

  if (shouldDisplayEmployeeInfoScreen) return <EmployeeInfo onSubmit={setEmployeeInfo} />;

  // check if answers
  // then give quadrant
  if (responseOfSurvey.isSurveyCompleted && answers.length && !isQuadrantClosed) {
    return <QuadrantChartSurveyResultPage answers={answers} onClose={() => setIsQuadrantClosed(true)} />;
  }

  // after exit quadrant
  // check
  // give rathing
  if (responseOfSurvey.isRateNeeded && responseOfSurvey.isSurveyCompleted && answers.length && isQuadrantClosed) {
    return <RateSurveyPage onSubmit={rating => onSubmitRateSurvey(rating)} />;
  }

  return (
    <SurveyComponent
      employeeInfo={employeeInfo}
      onSend={answers => {
        onSendAnswers(answers);
        navigate('/index/leave-feedback');
      }}
    />
  );
};

export default SurveyPageComponent;

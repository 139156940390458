import { useState } from 'react';

import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';
import CulturlabsRatingControl from '../atoms/CulturlabsRatingControl/CulturlabsRatingControl';
import CulturlabsTextArea from '../atoms/CulturlabsTextarea/CulturlabsTextarea';

const RateSurveyPage = ({ onSubmit = emptyArrowFunc }) => {
  const [rateState, setRateState] = useState();
  const updateState = (control, value) => {
    const updated = {
      ...rateState,
      [control]: value,
    };
    setRateState(updated);
  };

  return (
    <div className="flex flex-col items-center rounded-24 h-[calc(100vh-40px)] w-[100%] p-[48px] overflow-y-auto">
      <h1 className="text-lato text-main-black text-xxl font-bold mb-[32px]">Please rate this survey</h1>

      <div className="bg-white shrink w-[890px] pt-[40px] pb-[32px] rounded-[24px]">
        <p className="text-inter font-bold text-main-black text-ml mb-[24px] text-center">
          How much did you like this survey?
        </p>
        <div className="grid mb-[32px] grid-cols-3">
          <p className="justify-self-end">Extremely disliked</p>
          <CulturlabsRatingControl
            onControlChange={value => updateState('likeSurvey', value)}
            className="mx-[32px]"
          />{' '}
          <p>Extremely liked</p>
        </div>

        <p className="text-inter font-bold text-main-black text-ml mb-[24px] text-center">
          How interesting were the questions?
        </p>
        <div className="grid mb-[32px] grid-cols-3">
          <p className="justify-self-end">Extremely uninteresting</p>
          <CulturlabsRatingControl
            onControlChange={value => updateState('questionsAreInterested', value)}
            className="mx-[32px]"
          />{' '}
          <p>Extremely interesting</p>
        </div>
        <p className="text-inter font-bold text-main-black text-ml mb-[24px] text-center">
          How biased did you think the questions were?
        </p>
        <div className="grid mb-[32px] grid-cols-3">
          <p className="justify-self-end">Extremely biased</p>
          <CulturlabsRatingControl
            onControlChange={value => updateState('howBiased', value)}
            className="mx-[32px]"
          />{' '}
          <p>Extremely unbiased</p>
        </div>
        <p className="text-inter font-bold text-main-black text-ml mb-[24px] text-center">
          How easy or difficult did you find it to understand the questions?
        </p>
        <div className="grid mb-[32px] grid-cols-3">
          <p className="justify-self-end">Extremely difficult</p>
          <CulturlabsRatingControl
            onControlChange={value => updateState('howEasyQuestionsAre', value)}
            className="mx-[32px]"
          />{' '}
          <p>Extremely easy</p>
        </div>
        <p className="text-inter font-bold text-main-black text-ml mb-[24px] text-center">
          Please rate this survey overall
        </p>
        <div className="grid mb-[32px] grid-cols-3">
          <p className="justify-self-end">Extremely poor</p>
          <CulturlabsRatingControl
            onControlChange={value => updateState('overallRate', value)}
            className="mx-[32px]"
          />{' '}
          <p>Extremely good</p>
        </div>
        <p className="text-inter font-bold text-main-black text-ml mb-[24px] text-center">
          Do you have any other comments?
        </p>
        <CulturlabsTextArea
          placeholderText="Type your comment here"
          className="mx-[90px] mb-[24px] px-0 min-h-[48px]"
          textareaClass="text-m placeholder:text-m p-[16px] min-h-[48px]"
          initValue={rateState?.comments}
          handleChange={event => updateState('comments', event.target.value)}
        ></CulturlabsTextArea>
        <CulturlabsButton
          className="mx-auto py-0 h-[48px] w-[230px] flex items-center justify-center"
          onClick={() => onSubmit(rateState)}
        >
          <span>Submit</span>
        </CulturlabsButton>
      </div>
    </div>
  );
};

export default RateSurveyPage;

import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as KebabSVG } from '../../assets/kebab-menu.svg';
import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import APIService from '../../services/API.service';
import InviteUserModal from '../InviteUserModal/InviteUserModal';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';
import CulturlabsCheckbox from '../atoms/CulturlabsCheckBox/CulturlabsCheckBox';
import CulturlabsModalListItem from '../atoms/CulturlabsModalListItem/CulturlabsModalListItem';

const ListOfEmployeesPage = () => {
  const [users, setUsers] = useState([]);
  const [showModalOnLine, setShowModalOnLine] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isInviteModalShown, setIsInviteModalShown] = useState(false);
  const modalRef = useRef(null);
  const token = localStorage.getItem('token');
  const me = JSON.parse(localStorage.getItem('me'));
  const isSuperAdmin = me?.isSuperAdmin;
  const navigate = useNavigate();

  useEffect(() => {
    APIService.call({
      method: 'get',
      url: APIService.urls.users,
      headers: { Authorization: `Bearer ${token}` },
    })
      .catch(emptyArrowFunc)
      .then(({ data }) => setUsers(data?.data));
  }, []);

  const toggleUserModal = (rowIndex, user) => {
    if (isModalOnLineShown(rowIndex, showModalOnLine)) {
      setShowModalOnLine(null);
    } else {
      setShowModalOnLine({
        line: rowIndex,
        user: user?.id || 'ALL',
      });
    }
  };

  useEffect(() => {
    const handleClickOutsideModal = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModalOnLine(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutsideModal);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideModal);
    };
  }, []);

  const hideModal = () => setShowModalOnLine(null);

  const toggleInviteModal = () => setIsInviteModalShown(!isInviteModalShown);
  const getUsersByArgument = (user, users) => (user === 'ALL' ? users : [user]);

  const assignSurvey = async (user, surveyType, selectedUsers) => {
    return await Promise.all(
      getUsersByArgument(user, selectedUsers).map(user =>
        APIService.call({
          method: 'post',
          url: APIService.urls.assignedSurvey,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { surveyType, user },
        }).catch(emptyArrowFunc),
      ),
    );
  };
  const deleteUser = async (user, users) => {
    return await Promise.all(
      getUsersByArgument(user, users).map(user =>
        APIService.call({
          method: 'delete',
          url: `${APIService.urls.users}/${user?.id}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).catch(emptyArrowFunc),
      ),
    );
  };
  const onDelete = async (user, users, selectedUsers, setUsers) => {
    await deleteUser(user || 'ALL', selectedUsers);
    setUsers(users.filter(user => selectedUsers.every(selected => selected.id != user.id)));
  };
  const isAllowedPulseForUser = surveys =>
    surveys
      .filter(({ completed }) => completed)
      .some(survey => survey.type === 'full' && !surveys.some(s => s.type === 'pulse'));

  const isAllowedFullForUser = surveys => surveys?.some(survey => survey.type === 'full');

  const getRowClasses = rowIndex =>
    `${
      rowIndex % 2 ? 'bg-white' : 'bg-table-blue'
    } grid grid-cols-7 relative h-[48px] px-[24px] py-[12px] [&>*]:font-[600] [&>*]:font-lato [&>*]:text-m`;

  const isModalOnLineShown = (index, showModalOnLine) => showModalOnLine?.line === index;

  const getTypeOfSurvey = surveys => {
    try {
      return surveys.reduce((reducer, survey) => (reducer += survey.type + '\n'), '');
    } catch {
      return null;
    }
  };

  const toggleSelectAll = (isSelected, users, setSelectedUsers) => {
    const updatedSelection = isSelected?.target?.checked ? users : [];
    setSelectedUsers(updatedSelection);
  };

  const toggleUserSelection = (user, isChecked, selectedUsers, setSelectedUsers) => {
    let updatedUsers = selectedUsers.filter(({ id }) => user.id !== id);

    if (isChecked) {
      updatedUsers = [...updatedUsers, user];
    }
    setSelectedUsers(updatedUsers);
  };

  const isUserSelected = (user, selectedUsers) => !!selectedUsers.find(({ id }) => user.id == id);

  const onInvite = payload => {
    APIService.call({
      method: 'post',
      url: APIService.urls.invitedUsers,
      headers: { Authorization: `Bearer ${token}` },
      data: payload,
    }).catch(emptyArrowFunc);
  };
  const getModal = (user, users, setUsers, selectedUsers, hideModal, isAllowedPulse, isAllowedFull) => (
    <div
      className="absolute flex flex-col bg-white shadow-employee-modal z-10 rounded-[12px] [&>*]:my-[8px] right-[80px] p-[16px]"
      onClick={hideModal}
      ref={modalRef} // Add a ref to the modal
    >
      <CulturlabsModalListItem
        type="button"
        label="Send Full Survey link"
        disabled={isAllowedFull}
        onClick={() => assignSurvey(user, 'full', selectedUsers)}
      />
      <CulturlabsModalListItem
        type="button"
        label="Send Pulse Survey link"
        disabled={!isAllowedPulse}
        onClick={() => isAllowedPulse && assignSurvey(user, 'pulse', selectedUsers)}
      />
      {user !== 'ALL' && (
        <CulturlabsModalListItem
          type="link"
          label="Edit Employee Profile"
          href={`/index/list-of-employees/${user?.id}`}
        />
      )}
      <CulturlabsModalListItem
        type="button"
        label="Delete Employee"
        red
        onClick={() => onDelete(user, users, selectedUsers, setUsers)}
      />
    </div>
  );
  return (
    <>
      <div className="w-full h-[100vh] flex flex-col justify-start p-[48px] overflow-y-auto">
        <div className="w-full flex flex-col justify-start">
          <div className="flex flex-row w-full">
            <h1 className="text-main-black text-xxl mb-[40px] grow">List of Employees</h1>
            {isSuperAdmin ? (
              <CulturlabsButton
                type="button"
                className="w-[270px] mr-5"
                onClick={() => {
                  navigate('/upload-csv');
                }}
              >
                Upload CSV
              </CulturlabsButton>
            ) : (
              <></>
            )}
            <CulturlabsButton type="button" className="w-[270px]" onClick={toggleInviteModal}>
              Invite User
            </CulturlabsButton>
          </div>
          {isInviteModalShown && (
            <InviteUserModal closeModal={() => setIsInviteModalShown(false)} onInvite={payload => onInvite(payload)} />
          )}
          <div className="flex rounded-[24px] overflow-scroll h-[calc(100vh-160px)]">
            <div className="grow">
              <div>
                <div className={getRowClasses()}>
                  <div
                    className="pl-[24px]"
                    onClick={isSelected => toggleSelectAll(isSelected, users, setSelectedUsers)}
                  >
                    <CulturlabsCheckbox label={' '}></CulturlabsCheckbox>
                  </div>
                  <div>Email</div>
                  <div>Organization</div>
                  <div>Department</div>
                  <div>Team</div>
                  <div>Type of Survey</div>
                  <div onClick={() => toggleUserModal('ALL', 'ALL')}>
                    <KebabSVG className="cursor-pointer" />
                  </div>
                  <div></div>
                  {isModalOnLineShown('ALL', showModalOnLine) &&
                    getModal(
                      'ALL',
                      users,
                      setUsers,
                      selectedUsers,
                      hideModal,
                      users.every(({ surveys }) => isAllowedPulseForUser(surveys)),
                    )}
                </div>
              </div>
              <div>
                {users.map((user, index) => (
                  <div className={getRowClasses(index + 1)} key={user?.id}>
                    <div className="pl-[24px]">
                      <CulturlabsCheckbox
                        label={' '}
                        inputID={user?.id}
                        incomingValue={isUserSelected(user, selectedUsers)}
                        onChange={isChecked => toggleUserSelection(user, isChecked, selectedUsers, setSelectedUsers)}
                      ></CulturlabsCheckbox>
                    </div>
                    <div>{user.email}</div>
                    <div>{user.department?.organization?.name}</div>
                    <div>{user.department?.name}</div>
                    <div>{user.team?.name}</div>
                    <div className="capitalize overflow-y-auto">
                      {getTypeOfSurvey(user.surveys.filter(({ completed }) => !completed))}
                    </div>
                    <div onClick={() => toggleUserModal(index, user)}>
                      <KebabSVG className="cursor-pointer" />
                    </div>
                    {isModalOnLineShown(index, showModalOnLine) &&
                      getModal(
                        user || 'ALL',
                        users,
                        setUsers,
                        selectedUsers,
                        hideModal,
                        isAllowedPulseForUser(user.surveys),
                        isAllowedFullForUser(user.surveys),
                      )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListOfEmployeesPage;

import { useNavigate } from 'react-router-dom';

import { ReactComponent as LogoSVG } from '../../../src/assets/Logo.svg';
import { ReactComponent as FolderSVG } from '../../assets/empty-state-svg/folder.svg';
import { ReactComponent as ReviewEmailSVG } from '../../assets/empty-state-svg/review-email.svg';
import { ReactComponent as LeftArrowSVG } from '../../assets/left-arrow.svg';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';

const EmptyStatePage = ({ type, hideLogo }) => {
  const navigate = useNavigate();
  return (
    <section className="-m-[48px] flex flex-col w-full h-[100vh] bg-lightgray px-[24px] p-[48px] overflow-y-auto">
      {!hideLogo && <LogoSVG className="mt-[40px] fill-main-black h-[64px] w-[168px]" />}

      <main className="w-full grow flex flex-col justify-center items-center">
        {getMainImage(type)}
        <p className="mt-[56px] font-inter text-sub-factor">{getText(type)}</p>
        {/* <p>We've sent an email with the verification link to the address: john@doe.com</p> */}
      </main>
      {isShowButton(type) && (
        <CulturlabsButton
          secondary
          className="w-[140px] mt-auto border-[1px] border-solid border-main-blue [&>*]:text-main-blue [&>*]:fill-main-blue mb-[24px] text-main-blue flex flex-row justify-center items-center"
          onClick={() => onGoBack(type, navigate)}
        >
          <LeftArrowSVG className="w-[24px] h-[24px]" />
          Go Back
        </CulturlabsButton>
      )}
    </section>
  );
};

export default EmptyStatePage;

const isShowButton = type => type !== 'noSurveys';

const getMainImage = componentType => {
  switch (componentType) {
    case 'confirmEmail':
    case 'resetPassword':
      return <ReviewEmailSVG />;
    case 'noSurveys':
      return <FolderSVG />;
    default:
      console.error('Image does not match component type');
      break;
  }
};

const getRouteToNavigateTo = componentType => {
  switch (componentType) {
    case 'confirmEmail':
      return '/login';
    case 'noSurveys':
      return '';
    case 'resetPassword':
      return '/login';
    default:
      console.error('Navigation does not match component type');
      break;
  }
};

const getText = componentType => {
  switch (componentType) {
    case 'confirmEmail':
      return 'Please check your email to complete registration...';
    case 'noSurveys':
      return 'No surveys currently assigned to you';
    case 'resetPassword':
      return 'Password reset link was sent to your email';
    default:
      console.error('Text does not match component type');
      break;
  }
};

const onGoBack = (componentType, navigate) => {
  const nextRoute = getRouteToNavigateTo(componentType);

  navigate(nextRoute);
};

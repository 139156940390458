import { ReactComponent as TemperatureLogoSVG } from '../../assets/Logo.svg';
import { ReactComponent as AuthKeySVG } from '../../assets/auth-key.svg';
import { ReactComponent as AuthSVG } from '../../assets/auth.svg';
import ForgotPasswordPage from '../ForgotPasswordPage/ForgotPasswordPage.js';
import LoginPage from '../LoginPage/LoginPage';
import RegistrationPage from '../RegistrationPage/RegistrationPage';

const AuthScreen = ({ authType }) => {
  return (
    <div className="h-[100vh] flex flex-row w-full">
      <div className="bg-white w-[600px]">
        <header className="pt-[40px] pb-[64px] px-[72px]">
          <TemperatureLogoSVG className="fill-main-black" />
        </header>
        <main className="px-[132px]">
          {authType === 'login' && <LoginPage />}
          {authType === 'registration' && <RegistrationPage />}
          {authType === 'forgotPassword' && <ForgotPasswordPage />}
        </main>
      </div>
      <div className="flex flex-col justify-center items-center flex-grow bg-lightgray">
        {(authType === 'login' || authType === 'registration') && <AuthSVG className="w-[80%]" />}
        {authType === 'forgotPassword' && <AuthKeySVG className="w-[80%]" />}
      </div>
    </div>
  );
};

export default AuthScreen;

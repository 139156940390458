import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as TemperatureLogoSVG } from '../../assets/Logo.svg';
import { ReactComponent as CatalogueSVG } from '../../assets/nav-bar/catalogue.svg';
import { ReactComponent as ContactSVG } from '../../assets/nav-bar/contact.svg';
import { ReactComponent as ExportSVG } from '../../assets/nav-bar/export.svg';
import { ReactComponent as LogoutSVG } from '../../assets/nav-bar/logout.svg';
import { ReactComponent as SettingsSVG } from '../../assets/nav-bar/settings.svg';
import { ReactComponent as SurveySVG } from '../../assets/nav-bar/survey.svg';
import { ReactComponent as TableSVG } from '../../assets/nav-bar/table.svg';
import { ReactComponent as TemperatureSVG } from '../../assets/nav-bar/temperature.svg';
import { ReactComponent as UploadSVG } from '../../assets/nav-bar/upload.svg';
import DisplayIfAllowed from '../../router/DisplayIfAllowed';
import useAppStore from '../../store';
import NavigationSidebarButton from '../NavigationSidebarButton/NavigationSidebarButton';

const NavigationSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const setAuth = useAppStore(state => state.setAuth);
  const haveSurvey = localStorage.getItem('haveSurvey') === 'true';
  const handleNavigation = (event, targetPath) => {
    if (
      haveSurvey &&
      location.pathname.includes('/index/survey') &&
      !window.confirm('If you leave the survey tab, all progress will be lost.')
    ) {
      event.preventDefault();
    } else {
      navigate(targetPath);
    }
  };

  const logout = event => {
    handleNavigation(event, '/');
    event.preventDefault();
    localStorage.removeItem('token');
    localStorage.removeItem('aboutToLogoutNotificationTimer');
    localStorage.removeItem('me');
    localStorage.removeItem('logoutTimer');
    setAuth({});
  };

  useEffect(() => {
    window.onbeforeunload = () => {
      if (haveSurvey && location.pathname.includes('/index/survey')) {
        return 'If you leave the survey tab, all progress will be lost.';
      }
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [haveSurvey, location.pathname]);

  return (
    <nav className="flex flex-col px-[12px] w-[140px] h-[100vh] bg-main-black rounded-tr-[24px] rounded-br-[24px] z-[9000]">
      <TemperatureLogoSVG className="fill-white mt-[24px] mx-auto mb-[40px]" />
      <DisplayIfAllowed rule="forAuthOnly" hideForCorporativeAdmin>
        <NavigationSidebarButton
          className="mb-[8px]"
          path="/index/survey"
          onClick={e => handleNavigation(e, '/index/survey')}
        >
          <SurveySVG className="h-[24px] w-[24px] shrink-0" />
          <span>Survey</span>
        </NavigationSidebarButton>
      </DisplayIfAllowed>
      <DisplayIfAllowed rule="forAdminOnly">
        <NavigationSidebarButton
          className="mb-[8px]"
          path="/index/temperature"
          onClick={e => handleNavigation(e, '/index/temperature')}
        >
          <TemperatureSVG className="h-[24px] w-[24px] shrink-0" />
          <span>Temperature</span>
        </NavigationSidebarButton>
      </DisplayIfAllowed>
      <DisplayIfAllowed rule="forAuthOnly" organizationAdminOnly>
        <NavigationSidebarButton
          className="mb-[8px]"
          path="/index/assign-survey"
          onClick={e => handleNavigation(e, '/index/assign-survey')}
        >
          <UploadSVG className="h-[24px] w-[24px] shrink-0" />
          <span>Assign Survey</span>
        </NavigationSidebarButton>
      </DisplayIfAllowed>
      <DisplayIfAllowed rule="forAuthOnly" organizationAdminOnly>
        <NavigationSidebarButton
          className="mb-[8px]"
          path="/index/employee-list"
          onClick={e => handleNavigation(e, '/index/employee-list')}
        >
          <TableSVG className="h-[24px] w-[24px] shrink-0" />
          <span>List of Employees</span>
        </NavigationSidebarButton>
      </DisplayIfAllowed>
      <DisplayIfAllowed rule="forAuthOnly" organizationAdminOnly>
        <NavigationSidebarButton
          className="mb-[8px]"
          path="/index/export-results"
          onClick={e => handleNavigation(e, '/index/export-results')}
        >
          <ExportSVG className="h-[24px] w-[24px] shrink-0" />
          <span>Export Results</span>
        </NavigationSidebarButton>
      </DisplayIfAllowed>
      <DisplayIfAllowed rule="forAuthOnly" organizationAdminOnly>
        <NavigationSidebarButton
          className="mb-[8px]"
          path="/index/temperature"
          onClick={e => handleNavigation(e, '/index/temperature')}
        >
          <TemperatureSVG className="h-[24px] w-[24px] shrink-0" />
          <span>Temperature Reading</span>
        </NavigationSidebarButton>
      </DisplayIfAllowed>
      <DisplayIfAllowed rule="forAuthOnly" anyAdminOnly>
        <NavigationSidebarButton
          className="mb-[8px]"
          path="/index/interventions"
          onClick={e => handleNavigation(e, '/index/interventions')}
        >
          <CatalogueSVG className="h-[24px] w-[24px] shrink-0" />
          <span>Intervention Catalogue</span>
        </NavigationSidebarButton>
      </DisplayIfAllowed>
      <DisplayIfAllowed rule="forAdminOnly">
        <NavigationSidebarButton
          className="mb-[8px]"
          path="/index/list-of-employees"
          onClick={e => handleNavigation(e, '/index/list-of-employees')}
        >
          <TableSVG className="h-[24px] w-[24px] shrink-0" />
          <span>List of Employees</span>
        </NavigationSidebarButton>
      </DisplayIfAllowed>
      <DisplayIfAllowed rule="forAuthOnly" hideForRegularCorporativeUser>
        <NavigationSidebarButton
          className="mb-[8px]"
          path="/index/user-settings"
          onClick={e => handleNavigation(e, '/index/user-settings')}
        >
          <SettingsSVG className="h-[24px] w-[24px] shrink-0" />
          <span>Settings</span>
        </NavigationSidebarButton>
      </DisplayIfAllowed>
      <NavigationSidebarButton
        className="mb-[8px]"
        path="/index/contact-us"
        onClick={e => handleNavigation(e, '/index/contact-us')}
      >
        <ContactSVG className="h-[24px] w-[24px] shrink-0" />
        <span>Contact Us</span>
      </NavigationSidebarButton>
      <DisplayIfAllowed rule="forAuthOnly" hideForRegularCorporativeUser>
        <NavigationSidebarButton className="mt-auto mb-[24px]" path="/logout" onClick={logout}>
          <LogoutSVG className="h-[24px] w-[24px] shrink-0" />
          <span>Logout</span>
        </NavigationSidebarButton>
      </DisplayIfAllowed>
    </nav>
  );
};

export default NavigationSidebar;

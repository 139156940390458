import React from 'react';
import Markdown from 'react-markdown';

import { ReactComponent as LeftArrowSVG } from '../../assets/left-arrow.svg';
import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import './InterventionCardFull.css';

// Custom link renderer
const LinkRenderer = ({ href, children }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" className="hover:underline">
      {children}
    </a>
  );
};

const InterventionCardFull = ({ intervention: { title, content }, close = emptyArrowFunc }) => {
  return (
    <div className="w-full rounded-[24px] bg-white overflow-hidden">
      <div className="p-[16px] h-[80px] bg-main-black w-full flex flex-row items-center">
        <div className="mr-[24px]" onClick={() => close()}>
          <LeftArrowSVG className="fill-white cursor-pointer" />
        </div>
        <h1 className="text-white font-lato sub-factor font-bold text-sub-factor text-center grow text-start">
          {title}
        </h1>
      </div>
      <div className="p-[16px] py-[32px] markdown">
        <Markdown components={{ a: LinkRenderer }}>{content}</Markdown>
      </div>
    </div>
  );
};

export default InterventionCardFull;

import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';
import CulturlabsChip from '../atoms/CulturlabsChip/CulturlabsChip';

const InterventionCard = ({
  intervention: { title, headline, factors },
  close = emptyArrowFunc,
  click = emptyArrowFunc,
}) => {
  return (
    <div className="flex flex-col basis-[283px] max-w-[300px] grow rounded-[24px] bg-white overflow-hidden">
      <div className="p-[16px] h-[80px] bg-main-black w-full flex flex-row items-center">
        <h1 className="text-white font-lato text-l text-center grow" onClick={close()}>
          {title}
        </h1>
      </div>

      <div className="p-[16px] flex flex-col grow">
        <div className="flex flex-row flex-wrap gap-[8px]">
          {factors.data?.map(({ attributes }) =>
            attributes?.subfactors?.map(({ id, attributes: { label } }) => (
              <CulturlabsChip key={id} label={label} disabled />
            )),
          )}
        </div>
        <p className="mt-[16px] text-ml font-[700]">Headline:</p>
        <p className="text-ml grow overflow-hidden">{headline}</p>
        <CulturlabsButton className="w-full mt-[16px]" onClick={() => click()}>
          View
        </CulturlabsButton>
      </div>
    </div>
  );
};

export default InterventionCard;

import { isEmpty } from 'lodash';

import useAppStore from '../store';

const useCurrentUser = () => {
  const userFromStore = useAppStore(state => state.me) ?? null;
  const me = localStorage.getItem('me');
  const parsedMe = me ? JSON.parse(me) : null;
  const currentUser = !isEmpty(userFromStore) ? userFromStore : parsedMe;

  let isCorporativeAdmin = undefined;
  if (currentUser?.organization) {
    isCorporativeAdmin = currentUser.organizationRole === 'admin';
  }

  return { currentUser, isCorporative: !!currentUser?.organization, isCorporativeAdmin };
};

export default useCurrentUser;

import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

import unwrapValueFromInputChangeEvent from '../../helpers/unwrapValueFromInputChangeEvent.helper';
import PolarAnswer from '../PolarAnswer/PolarAnswer';
import SurveyHeader from '../SurveyHeader/SurveyHeader';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';

const DEFAULT_ANSWER_VALUE = '50';

const QuestionPage = ({
  questions,
  currentQuestion,
  currentAnswer,
  onNext,
  onPrevious,
  isFirstQuestion,
  isLastQuestion,
}) => {
  // const [iDontKnowState, setIDontKnowState] = useState(false);
  const [isAdditionalCommentShownState, setIsAdditionalCommentShownState] = useState(false);
  const [additionalCommentState, setAdditionalCommentState] = useState('');
  const [answerValue, setAnswerValue] = useState(currentAnswer?.answerValue ?? DEFAULT_ANSWER_VALUE);
  const [isAnswerRangeSliderTouched, setIsAnswerRangeSliderTouched] = useState(false);

  useEffect(() => {
    setAnswerValue(currentAnswer?.answerValue ?? DEFAULT_ANSWER_VALUE);
    handleHideAdditionalCommentTextarea();
    handleResetAdditionalCommentState();
  }, [currentQuestion]);

  const currentQuestionNumber = questions.map(({ id }) => id)?.indexOf(currentQuestion?.id) + 1;

  const handleResetAdditionalCommentState = () => setAdditionalCommentState('');

  const handleHideAdditionalCommentTextarea = () => setIsAdditionalCommentShownState(false);

  return (
    <section className="w-full h-screen bg-lightgray p-[48px] overflow-y-scroll">
      <SurveyHeader
        title={currentQuestion?.content}
        sumNumber={questions?.length || 0}
        currentNumber={currentQuestionNumber || 0}
        currentAnswer={answerValue}
      />
      <PolarAnswer
        question={currentQuestion}
        value={answerValue}
        isTouched={isAnswerRangeSliderTouched}
        onChange={event => setAnswerValue(unwrapValueFromInputChangeEvent(event))}
        onTouch={setIsAnswerRangeSliderTouched}
      />

      <div className="flex flex-row justify-center items-center">
        <div className="relative select-none mt-[24px] bg-white flex flex-row items-center justify-center w-[240px] h-[40px] rounded-[24px] text-[20px] font-inter">
          <div className="absolute bg-white rotate-45 h-[16px] w-[16px] -top-[6px] right-[24px]"></div>
          Please click the bar
        </div>
      </div>

      <footer className="mt-auto flex flex-row justify-center w-full">
        {/* <div className="w-[360px] flex flex-col">
          {iDontKnowState && !isAdditionalCommentShownState && (
            <CulturlabsTextArea
              placeholderText="Remember: the purpose of these questions is for you to express what you feel, not what you know."
              className="p-[16px] mb-[8px]"
              textareaClass="h-[72px] text-m placeholder:text-m p-[16px]"
              handleChange={() => {}}
            />
          )}
          {isAdditionalCommentShownState && (
            <CulturlabsTextArea
              placeholderText="Please enter your comment here."
              className="p-[16px] mb-[8px]"
              initValue={additionalCommentState}
              textareaClass="h-[72px] text-m placeholder:text-m p-[16px]"
              handleChange={event => setAdditionalCommentState(event.target.value)}
            />
          )}
          <CulturlabsButton
            className="flex flex-row mb-[16px] justify-start px-5 items-center !text-[20px] bg-gray rounded-2 h-[40px] w-[250px] rounded-[10px]"
            onClick={() => setIDontKnowState(!iDontKnowState)}
            secondary={!iDontKnowState}
          >
            I don't know...
          </CulturlabsButton>
          <CulturlabsButton
            secondary
            className="flex flex-row h-[24px] justify-start px-5 items-center !text-[20px] bg-gray rounded-2 h-[40px] w-[250px] rounded-[10px]"
            onClick={() => setIsAdditionalCommentShownState(!isAdditionalCommentShownState)}
          >
            Additional comments
          </CulturlabsButton>
        </div> */}
        <div className="flex flex-row grow mt-6">
          <CulturlabsButton
            secondary
            disabled={isFirstQuestion}
            onClick={() =>
              onPrevious({
                questionId: currentQuestion?.id,
                answerValue,
                additionalCommentState: additionalCommentState,
              })
            }
            className="bg-gray h-[24px] rounded-[10px] mr-[16px] ml-auto"
          >
            <FontAwesomeIcon className="mr-[10px]" color="black" icon={faAngleLeft} />
            <span>Previous Question</span>
          </CulturlabsButton>
          {isLastQuestion ? (
            <CulturlabsButton
              disabled={!isAnswerRangeSliderTouched || !answerValue}
              onClick={() => {
                setIsAnswerRangeSliderTouched(false);

                onNext({
                  questionId: currentQuestion?.id,
                  answerValue,
                  additionalCommentState: additionalCommentState,
                });
              }}
            >
              <span className="mr-[10px]">End Survey</span>
            </CulturlabsButton>
          ) : (
            <CulturlabsButton
              disabled={!isAnswerRangeSliderTouched || isLastQuestion || !answerValue}
              onClick={() => {
                setIsAnswerRangeSliderTouched(false);
                onNext({
                  questionId: currentQuestion?.id,
                  answerValue,
                  additionalCommentState: additionalCommentState,
                });
              }}
              className="bg-gray h-[24px] rounded-[10px]"
            >
              <span className="mr-[10px]">Next Question</span>
              <FontAwesomeIcon color="black" icon={faAngleRight} />
            </CulturlabsButton>
          )}
        </div>
      </footer>
    </section>
  );
};

export default QuestionPage;

import { useState } from 'react';

import { ReactComponent as LogoSVG } from '../../../src/assets/Logo.svg';
import APIService from '../../services/API.service';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';

const CsvUploadPage = () => {
  const [file, setFile] = useState(null);
  const token = localStorage.getItem('token');

  const handleFileChange = e => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!file) {
      alert('Please select a CSV file first.');
      return;
    }
    if (!file.name.endsWith('.csv')) {
      alert('Only CSV files are allowed.');
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await APIService.call({
        method: 'post',
        url: APIService.urls.uploadCsv,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
      alert('File uploaded successfully!');
    } catch (error) {
      console.error('Error uploading file: ', error);
      alert('Error uploading file.');
    }
  };

  return (
    <div className="-my-[48px] flex flex-col w-full h-[100vh] bg-lightgray px-[24px]">
      <LogoSVG className="mt-[40px] fill-main-black h-[64px] w-[168px]" />
      <main className="w-full grow flex flex-col justify-center items-center">
        <h2 className="text-xl font-semibold mb-4">Upload CSV File</h2>
        <label>
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            class="text-sm text-grey-500
             file:mr-5 file:py-2 file:px-6
             file:rounded-full file:border-0
             file:text-sm file:font-medium
             file:bg-blue-50 file:text-blue-700
             hover:file:cursor-pointer hover:file:bg-amber-50
             hover:file:text-amber-700
             mb-4"
          />
        </label>
        <CulturlabsButton onClick={handleSubmit} className="bg-main-blue text-white px-4 py-2 rounded">
          Upload CSV
        </CulturlabsButton>
      </main>
    </div>
  );
};

export default CsvUploadPage;

import { useState } from 'react';

import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import APIService from '../../services/API.service';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';
import CulturlabsCheckbox from '../atoms/CulturlabsCheckBox/CulturlabsCheckBox';
import CulturlabsLink from '../atoms/CulturlabsLink/CulturlabsLink';
import CulturlabsTextArea from '../atoms/CulturlabsTextarea/CulturlabsTextarea';

const ContactUsPage = () => {
  const [feedbackState, setFeedbackState] = useState('');
  const [isFeedbackPersonalized, setIsFeedbackPersonalized] = useState(false);
  const [contactUsState] = useState('');
  const [isContactUsPersonalized] = useState(false);
  const isButtonDisabled = feedbackState.trim() === '';
  const token = localStorage.getItem('token');
  const buildPayload = type => {
    const payload = {};

    if (type === 'feedback') {
      payload.type = 'feedback';
      payload.message = feedbackState;
      payload.isAnonymous = !isFeedbackPersonalized;
    } else {
      payload.type = 'contactUs';
      payload.message = contactUsState;
      payload.isAnonymous = !isContactUsPersonalized;
    }

    return payload;
  };

  const postFeedback = async data => {
    APIService.call({
      method: 'post',
      url: APIService.urls.feedback,
      data,
      headers: { Authorization: `Bearer ${token}` },
    }).catch(emptyArrowFunc);
  };

  return (
    <>
      <div className="w-full h-[calc(100vh-90px)] p-[48px] overflow-y-auto">
        <h1 className="text-main-black text-xxl mb-[24px] font-bold">Contact Us</h1>
        <div className="max-w-[600px] flex flex-col items-flex-start mr-auto">
          <p className="font-inter text-l mb-[24px]">
            Have any questions, concerns, or feedback? Write to us below to get in touch.
          </p>
          <CulturlabsTextArea
            placeholderText=""
            className="mb-[24px] px-0 min-h-[180px]"
            textareaClass="text-m placeholder:text-m p-[16px] min-h-[180px]"
            initValue={feedbackState}
            handleChange={event => setFeedbackState(event.target.value)}
          ></CulturlabsTextArea>
          <CulturlabsCheckbox
            className="mb-[40px]"
            label={'Provide my contact details (otherwise anonymous)'}
            onChange={changeEvent => setIsFeedbackPersonalized(changeEvent)}
          >
            {' '}
          </CulturlabsCheckbox>
          <CulturlabsButton
            disabled={isButtonDisabled}
            type="button"
            className="mb-[40px] w-[336px]"
            onClick={() => postFeedback(buildPayload('feedback'))}
          >
            Leave Feedback
          </CulturlabsButton>
          <p>
            Email:{' '}
            <CulturlabsLink
              href="mailto:support@socialmachines.co.uk"
              className="text-l"
              label="support@socialmachines.co.uk"
            />
          </p>{' '}
          <p>
            Website:{' '}
            <CulturlabsLink
              href="https://socialmachines.co.uk/"
              className="text-l"
              label="https://socialmachines.co.uk/"
            />
          </p>
        </div>
      </div>
    </>
  );
};

export default ContactUsPage;

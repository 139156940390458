const getFilterOfOrganization = (selectionFiltersStore, isFiltersEnabled) => {
  if (!isFiltersEnabled) return () => true;

  const { team, department } = selectionFiltersStore;
  if (team !== '-' && team?.length) return answer => answer.team && answer.team.id == team;
  if (department !== '-' && department?.length)
    return answer => answer.department && answer.department.id == department;

  return () => true; // default to a function that always returns true
};

export default getFilterOfOrganization;

import { compact } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import CulturlabsButton from '../../components/atoms/CulturlabsButton/CulturlabsButton';
import CulturlabsSelect from '../../components/atoms/CulturlabsSelect/CulturlabsSelect';
import getAuthHeader from '../../helpers/getAuthHeader';
import { idMapper, labelRelationMapper } from '../../helpers/selectMappers.helper';
import APIService from '../../services/API.service';

const HEADER_TEXT = 'Please provide the required info about yourself';
const REGION_FINDER_LINK = 'https://unilever.sharepoint.com/sites/IPZ/SitePages/UnileverCompassGeographies.aspx';

export const EmployeeInfo = ({ onSubmit }) => {
  const [department, setDepartment] = useState(null);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [level, setLevel] = useState(null);
  const [levelOptions, setLevelOptions] = useState([]);
  const [region, setRegion] = useState(null);
  const [regionOptions, setRegionOptions] = useState([]);
  const [subregion, setSubregion] = useState(null);
  const [subregionOptions, setSubregionOptions] = useState([]);

  useEffect(() => {
    getFormOptions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFormOptions = () =>
    Promise.all([
      fetchUserData(APIService.urls.departments),
      fetchUserData(APIService.urls.levels),
      fetchUserData(APIService.urls.regions),
    ])
      .then(
        ([
          {
            data: { data: departments },
          },
          {
            data: { data: levels },
          },
          {
            data: { data: regions },
          },
        ]) => {
          setDepartmentOptions(departments);
          setLevelOptions(levels);
          setRegionOptions(regions);
        },
      )
      .catch(error => console.error(error));

  const fetchUserData = url =>
    APIService.call({
      method: 'get',
      url,
      headers: getAuthHeader(),
    });

  const allSelectsFulfilled = useMemo(() => {
    const requiredArray = [department, level, region, subregion];
    return compact(requiredArray).length === requiredArray.length;
  }, [department, level, region, subregion]);

  const handleChangeDepartment = event => {
    setDepartment(departmentOptions.find(({ id }) => String(id) === String(event.target.value)) || null);
  };

  const handleChangeLevel = event => {
    setLevel(levelOptions.find(({ id }) => String(id) === String(event.target.value)) || null);
  };

  const handleChangeRegion = event => {
    const selectedRegion = regionOptions.find(({ id }) => String(id) === String(event.target.value));
    setRegion(selectedRegion || null);
    setSubregion(null);
    setSubregionOptions(selectedRegion?.subregions || []);
  };

  const handleChangeSubregion = event => {
    setSubregion(subregionOptions.find(({ id }) => String(id) === String(event.target.value)) || null);
  };

  const handleSubmitClick = () => allSelectsFulfilled && onSubmit?.({ department, level, region, subregion });

  return (
    <div className="h-screen w-full xl:min-w-[630px] p-[24px] md:p-[48px] flex flex-col items-center overflow-y-scroll">
      <h1 className="text-lato text-main-black text-xl md:text-xxl font-bold mb-[32px]">{HEADER_TEXT}</h1>

      <div className="bg-white shrink p-[20px] md:p-[40px] rounded-[24px] flex flex-col items-center gap-[16px] md:gap-[32px]">
        <CulturlabsSelect
          className="grow w-full"
          label="department"
          listItems={departmentOptions}
          idMapper={idMapper}
          labelMapper={labelRelationMapper}
          onChange={(_, event) => handleChangeDepartment(event)}
        />

        <CulturlabsSelect
          className="grow w-full"
          label="level"
          listItems={levelOptions}
          idMapper={idMapper}
          labelMapper={labelRelationMapper}
          onChange={(_, event) => handleChangeLevel(event)}
        />

        <div className="w-full">
          <CulturlabsSelect
            className="grow w-full"
            label="region"
            listItems={regionOptions}
            idMapper={idMapper}
            labelMapper={labelRelationMapper}
            onChange={(_, event) => handleChangeRegion(event)}
          />
          <p className="font-inter text-m md:text-l w-full">
            Unsure what Region you’re in? Visit&nbsp;
            <a
              className="text-main-blue hover:underline"
              href={REGION_FINDER_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              this page for more information
            </a>
          </p>
        </div>

        <div className="w-full">
          <CulturlabsSelect
            className="grow w-full"
            label="sub-region"
            listItems={subregionOptions}
            idMapper={idMapper}
            labelMapper={labelRelationMapper}
            onChange={(_, event) => handleChangeSubregion(event)}
          />
          <p className="font-inter text-m md:text-l w-full">
            Unsure what Sub-region you’re in? Visit&nbsp;
            <a
              className="text-main-blue hover:underline"
              href={REGION_FINDER_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              this page for more information
            </a>
          </p>
        </div>

        <CulturlabsButton
          className="mx-auto h-[48px] w-[230px] flex items-center justify-center"
          disabled={!allSelectsFulfilled}
          onClick={handleSubmitClick}
        >
          <span>Submit</span>
        </CulturlabsButton>
      </div>
    </div>
  );
};

import { useEffect, useState } from 'react';

import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import factorsMapper from '../../helpers/factorsMapper.helper';
import getFilterOfOrganization from '../../helpers/getFilterOfOrganization.helper';
import proceedAnswerValue from '../../helpers/proceedAnswerValue.helper';
import APIService from '../../services/API.service';
import useAppStore from '../../store';
import FactorCard from '../FactorCard/FactorCard';
import Loading from '../Loading/Loading';
import NavTabsTemperature from '../NavTabsTemperature/NavTabsTemperature';
import StatisticsSelectionControls from '../StatisticsSelectionControls/StatisticsSelectionControls';
import NoResultsBlock from './NoResultsBlock';

const TemperatureFactorsPage = () => {
  const selectionFiltersStore = useAppStore(state => state.selectionFilters);
  const setSelectionFilters = useAppStore(state => state.setSelectionFilters);
  const isFiltersEnabled = Object.values(selectionFiltersStore).filter(value => value !== '-')?.length > 0;

  const [answers, setAnswers] = useState([]);
  const [sortedStatistics, setSortedStatistics] = useState({});
  const [filteredStat, setFilteredStat] = useState([]);
  const [factors, setFactors] = useState([]);
  const [loading, setLoading] = useState(false);

  const init = async () => {
    try {
      setLoading(true);

      const bearer = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${bearer}` };

      await APIService.call({
        method: 'get',
        url: APIService.urls.questionsFactors,
        headers,
      })
        .catch(emptyArrowFunc)
        .then(({ data }) => {
          const flat = data.data.map(({ id, attributes }) => ({
            id,
            ...attributes,
          }));

          setFactors(flat);
        });

      await APIService.call({
        method: 'get',
        url: APIService.urls.questionAnswer,
        params: {
          validDepartmentsOnly: true,
        },
        headers,
      })
        .catch(emptyArrowFunc)
        .then(({ data }) => {
          setAnswers(data.data);
        });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const sortedByFactor = answers.reduce((reducer, answer) => {
      if (!answer) return reducer;
      const { value: factor } = answer.factor;

      const answerValue = proceedAnswerValue(answer.answer_value);

      if (!reducer[factor]?.length) reducer[factor] = [];

      reducer[factor].push({
        answerValue,
        department: answer.department,
        team: answer.team,
      });

      return reducer;
    }, {});

    setSortedStatistics(sortedByFactor);
  }, [answers]);

  useEffect(() => {
    calculateStatisticsByFilters();
  }, [sortedStatistics]);

  useEffect(() => {
    setSelectionFilters({ department: '-', team: '-' });
  }, []);

  useEffect(() => {
    calculateStatisticsByFilters();
  }, [selectionFiltersStore]);

  const calculateStatisticsByFilters = () => {
    const statOfFiltered = Object.keys(sortedStatistics).reduce((reducerFactors, factor) => {
      const filterOfCurrentSelection = getFilterOfOrganization(selectionFiltersStore, isFiltersEnabled);

      const filteredAnswers = sortedStatistics[factor]?.filter(question => filterOfCurrentSelection(question));

      const sumByFactor =
        filteredAnswers.reduce((sumAnswers, answer) => {
          sumAnswers += answer.answerValue;
          return sumAnswers;
        }, 0) / filteredAnswers.length;

      reducerFactors.push({
        label: factorsMapper(factors, factor),
        value: sumByFactor,
        url: getUrlByFactor(factor),
      });

      return reducerFactors;
    }, []);

    setFilteredStat(statOfFiltered);
  };

  const cards = filteredStat.map(factor => <FactorCard key={factor.label} options={factor} />);

  return (
    <section className="w-full bg-lightgray overflow-y-auto p-[48px]">
      <div className="flex flex-col w-full h-[calc(100vh-40px)]">
        <h1 className="text-main-black text-xxl font-lato font-bold">Temperature</h1>
        <NavTabsTemperature className="my-[32px]" />
        <Loading loading={loading} loadingMessage="Generating Results">
          {cards.length ? (
            <>
              <StatisticsSelectionControls />
              <div className="flex flex-row flex-wrap gap-[24px] justify-items-start pb-[70px]">{cards}</div>
            </>
          ) : (
            <NoResultsBlock />
          )}
        </Loading>
      </div>
    </section>
  );
};

export default TemperatureFactorsPage;

const getUrlByFactor = factor => {
  const mapping = {
    Leadership: '/index/temperature/leadership',
    Policy: '/index/temperature/policy',
    Knowledge: '/index/temperature/knowledge',
    SocialEnvironment: '/index/temperature/social-environment',
  };
  return mapping[factor];
};

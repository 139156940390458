import jwt from 'jwt-decode';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import isSelected from '../../helpers/isSelected';
import onSelectChange from '../../helpers/onSelectChange.helper';
import { idMapper, labelMapper, labelRelationMapper } from '../../helpers/selectMappers.helper';
import unwrapValueFromInputChangeEvent from '../../helpers/unwrapValueFromInputChangeEvent.helper';
import APIService from '../../services/API.service';
import useAppStore from '../../store';
import TermsAndConditionsModal from '../TermsAndConditionsModal/TermsAndConditionsModal';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';
import CulturlabsCheckbox from '../atoms/CulturlabsCheckBox/CulturlabsCheckBox';
import CulturlabsInput from '../atoms/CulturlabsInput/CulturlabsInput';
import CulturlabsLink from '../atoms/CulturlabsLink/CulturlabsLink';
import CulturlabsSelect from '../atoms/CulturlabsSelect/CulturlabsSelect';
import callOrgItems from '../helpers/callOrgItems.helper';

const RegistrationPage = () => {
  const setRegisterForm = useAppStore(state => state.setRegisterForm);
  const registerFormStore = useAppStore(state => state.registerForm);

  const [organizations, setOrganizations] = useState([]);
  const [teams, setTeams] = useState([]);
  const [currentDepartments, setCurrentDepartments] = useState([]);
  const [currentTeams, setCurrentTeams] = useState([]);

  const [searchParams] = useSearchParams();
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [usersDataFromToken, setUsersDataFromToken] = useState(null);
  const [registrationToken, setRegistrationToken] = useState(null);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const teamRef = useRef(null);
  const departmentRef = useRef(null);

  const callDepartments = async token => {
    const departments = await callOrgItems(`${APIService.urls.departments}`, token);
    setCurrentDepartments(departments);

    return departments;
  };

  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    if (!searchParams) {
      console.error('RegistrationToken Should be provided');
    } else {
      const registrationToken = searchParams.get('registration-token');
      if (!registrationToken?.length) return;
      setRegistrationToken(registrationToken);
    }
  }, []);

  useEffect(() => {
    const isPasswordMatch = registerFormStore.password === registerFormStore.passwordCheck;
    const isAgreedToTerms = registerFormStore.agreeTerms;
    const isPasswordValid = registerFormStore.password?.length >= 6; // Minimum 6 characters

    setFormValid(
      usersDataFromToken?.email &&
        isPasswordMatch &&
        isAgreedToTerms &&
        isPasswordValid &&
        isSelected(selectedOrg?.id) &&
        isSelected(registerFormStore.team) &&
        isSelected(registerFormStore.department),
    );
  }, [registerFormStore, selectedOrg]);

  useEffect(() => {
    if (!registrationToken) return;

    onFetchOrganization().catch(emptyArrowFunc);
    const { organization, isAdmin, email } = jwt(registrationToken);
    setUsersDataFromToken({ isAdmin, email });
    const { id, ...rest } = organization;
    const transformedOrg = {
      id,
      attributes: { ...rest },
    };
    setOrganizations([transformedOrg]);
    setSelectedOrg(transformedOrg);
    onSelectChange('Organization', id, onOrganizationSelected);
  }, [registrationToken]);

  const navigate = useNavigate();

  const onSubmit = async event => {
    event.preventDefault();
    APIService.call({
      method: 'post',
      url: APIService.urls.users,
      data: {
        ...registerFormStore,
        email: usersDataFromToken.email,
        organization: selectedOrg?.id,
        token: registrationToken,
      },
    }).catch(emptyArrowFunc);

    navigate('/confirm-email');
  };

  const onFetchOrganization = async () => {
    const depts = await callDepartments(registrationToken);
    setCurrentDepartments(depts);

    const teams = depts?.reduce((reducer, currentDept) => {
      return [
        ...reducer,
        ...currentDept.teams.map(team => ({
          departmentId: currentDept.id,
          ...team,
        })),
      ];
    }, []);
    setTeams(teams);
  };

  const handleInputChange = (formControlName, originalEvent) => {
    const formControlUpdatedValue =
      originalEvent instanceof Object ? unwrapValueFromInputChangeEvent(originalEvent) : originalEvent;

    const formUpdate = {
      ...registerFormStore,
      [formControlName]: formControlUpdatedValue || undefined,
    };
    setRegisterForm(formUpdate);
  };

  const onOrganizationSelected = async (formUpdate, selectedOrganizationId) => {
    // Clean team and department if  Department changed
    formUpdate['department'] = null;
    formUpdate['team'] = null;

    departmentRef.current.clearValue();
    teamRef.current.clearValue();

    setCurrentTeams([]);
    setCurrentDepartments([]);

    if (isSelected(selectedOrganizationId)) {
      setSelectedOrg(organizations.find(({ id }) => id === +selectedOrganizationId));
      onFetchOrganization().catch(emptyArrowFunc);
    }
  };

  const onDepartmentSelected = (formUpdate, selectedDepartment) => {
    formUpdate['team'] = null;
    const teamsOfDepartment = teams.filter(currentTeam => currentTeam.departmentId == selectedDepartment);
    setCurrentTeams(teamsOfDepartment);

    // Clean team if  Department changed
    teamRef.current.clearValue();
  };

  return (
    <>
      <h1 className="text-main-black text-xxl mb-[16px]">Registration</h1>
      <div className="min-h-[calc(100vh-214px)] overflow-y-auto">
        <p className="font-inter text-l mb-[24px]">
          Already have an account? <CulturlabsLink href="/login" className="text-l" label="Login" />
        </p>
        <CulturlabsInput
          label="work email"
          className="mb-[16px]"
          disabled
          required
          initValue={usersDataFromToken?.email}
        />
        <CulturlabsInput
          inputType="password"
          label="create a password"
          className="mb-[16px]"
          required
          handleChange={changeEvent => handleInputChange('password', changeEvent)}
        />
        <CulturlabsInput
          inputType="password"
          label="repeat a password"
          className="mb-[16px]"
          required
          handleChange={changeEvent => handleInputChange('passwordCheck', changeEvent)}
        />
        <CulturlabsSelect
          className="mb-[16px]"
          label="Organization"
          initValue={selectedOrg?.id}
          listItems={organizations}
          idMapper={idMapper}
          labelMapper={labelMapper}
          required
          onChange={(type, event) =>
            onSelectChange(type, event, setRegisterForm, registerFormStore, onOrganizationSelected)
          }
        ></CulturlabsSelect>
        <CulturlabsSelect
          ref={departmentRef}
          className="mb-[16px]"
          label="Department"
          listItems={currentDepartments}
          idMapper={idMapper}
          labelMapper={labelRelationMapper}
          required
          onChange={(type, event) =>
            onSelectChange(type, event, setRegisterForm, registerFormStore, onDepartmentSelected)
          }
        ></CulturlabsSelect>
        <CulturlabsSelect
          ref={teamRef}
          className="mb-[16px]"
          label="Team"
          listItems={currentTeams}
          idMapper={idMapper}
          labelMapper={labelRelationMapper}
          required
          onChange={(type, event) => onSelectChange(type, event, setRegisterForm, registerFormStore)}
        ></CulturlabsSelect>
        <CulturlabsCheckbox
          label={'I Agree to the'}
          onChange={changeEvent => handleInputChange('agreeTerms', changeEvent)}
        >
          {' '}
          <CulturlabsLink
            className="ml-[4px] text-m font-semibold"
            label="Terms and Conditions"
            onClick={event => {
              event.preventDefault();
              setIsModalOpened(true);
            }}
          />
        </CulturlabsCheckbox>
        <CulturlabsButton disabled={!formValid} type="submit" className="mt-[24px] mb-[48px] w-full" onClick={onSubmit}>
          Register
        </CulturlabsButton>
        {isModalOpened && <TermsAndConditionsModal closeModal={() => setIsModalOpened(false)} />}
      </div>
    </>
  );
};

export default RegistrationPage;

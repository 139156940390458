import { useEffect, useState } from 'react';

const CulturlabsTextArea = ({ className, textareaClass, handleChange, placeholderText, disabled, initValue }) => {
  const [value, setValue] = useState(initValue);

  useEffect(() => {
    setValue(initValue || '');
  }, [initValue]);

  return (
    <div className={`flex flex-col relative ${className || ''}`}>
      <textarea
        value={value}
        disabled={disabled}
        className={`text-[18px] font-inter placeholder:text-[18px] rounded-[8px] outline-none border-solid border-gray-01 hover:border-blue-01 focus:border-blue-01 focus:shadow-input border ${textareaClass}`}
        placeholder={placeholderText || 'Type text here'}
        onChange={changeEvent => handleChange(changeEvent)}
      />
    </div>
  );
};

export default CulturlabsTextArea;

import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import checkmarkImage from '../../assets/images/checkmark.webp';
import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import rejectInsertedSpace from '../../helpers/rejectInsertedSpace.helper';
import unwrapValueFromInputChangeEvent from '../../helpers/unwrapValueFromInputChangeEvent.helper';
import { NewPasswordValidation, isEmailValid as emailValidator } from '../../helpers/validators';
import APIService from '../../services/API.service';
import useAppStore from '../../store';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';
import CulturlabsInput from '../atoms/CulturlabsInput/CulturlabsInput';
import CulturlabsLink from '../atoms/CulturlabsLink/CulturlabsLink';

const ForgotPasswordPage = () => {
  const [forgotPasswordForm, setForgotPasswordForm] = useState({});
  const [searchParams] = useSearchParams();
  const [changePasswordToken, setChangePasswordToken] = useState(null);
  const [isNewPasswordSent, setIsNewPasswordSent] = useState(false);
  const isNewPasswordValidStore = useAppStore(state => state.isNewPasswordValid);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isEmailFieldTouched, setIsEmailFieldTouched] = useState(false);
  const [isEmailSubmitAllowed, setIsEmailSubmitAllowed] = useState(false);

  const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(true);
  const [apiError, setApiError] = useState({});

  let trimmedEmail = forgotPasswordForm.email?.trim();
  let verifiedEmail = emailValidator(trimmedEmail);
  let verifiedNewPassword = Object.values(isNewPasswordValidStore).every(Boolean);

  const validateEmailField = useCallback(() => setIsEmailValid(verifiedEmail), [verifiedEmail]);

  useEffect(() => {
    if (isEmailFieldTouched) validateEmailField();
  }, [forgotPasswordForm.email, validateEmailField, isEmailFieldTouched]);

  useEffect(() => {
    setIsEmailSubmitAllowed(verifiedEmail);
  }, [verifiedEmail]);

  useEffect(() => {
    if (!searchParams) {
      console.error('Code for reset password should be provided');
    } else {
      const changePasswordToken = searchParams.get('code');
      if (!changePasswordToken?.length) return;
      setChangePasswordToken(changePasswordToken);
    }
  }, []);

  const navigate = useNavigate();

  const onSubmit = async event => {
    event.preventDefault();
    if (!changePasswordToken?.length) {
      await onForgot();
    } else {
      await onReset();
    }
  };

  const onForgot = async () => {
    const response = await APIService.call({
      method: 'post',
      url: APIService.urls.forgotPassword,
      data: forgotPasswordForm,
    }).catch(emptyArrowFunc);

    if (response?.status === 200 && response?.data?.ok) {
      navigate('/reset-password');
    }
  };

  const onReset = async () => {
    try {
      // oldPasswordCheck route
      await APIService.call({
        method: 'post',
        url: APIService.urls.oldPasswordCheck,
        data: { ...forgotPasswordForm, code: changePasswordToken },
      });

      // resetPassword route
      await APIService.call({
        method: 'post',
        url: APIService.urls.resetPassword,
        data: { ...forgotPasswordForm, code: changePasswordToken },
      });

      setIsNewPasswordSent(true);
      localStorage.setItem('expiredCode', changePasswordToken);
    } catch (error) {
      const errorObject = {
        isError: false,
        message: '',
      };

      const res = error.response;
      const req = error.request;

      // Check if the error response exists and has relevant data
      if (res) {
        if (res.status === 400 && res.data?.error.details.type === 'pass') {
          errorObject.isError = true;
          errorObject.message = res.data?.error.message;
        } else if (res.status === 500) {
          errorObject.message = 'Server error, please try again later.';
        } else {
          // General fallback message
          errorObject.message = res.data?.error.message || 'Something went wrong. Please try again.';
        }
      } else if (req) {
        // Request was made, but no response received (network issue or timeout)
        errorObject.message = 'Network error, please check your connection.';
      } else {
        // Something else caused the error
        errorObject.message = 'An unexpected error occurred. Please try again.';
      }

      setApiError(errorObject);
    }
  };

  const handleInputChange = (formControlName, originalEvent) => {
    const formControlUpdatedValue =
      originalEvent instanceof Object ? unwrapValueFromInputChangeEvent(originalEvent) : originalEvent;

    const formUpdate = {
      ...forgotPasswordForm,
      [formControlName]: formControlUpdatedValue || undefined,
    };
    setForgotPasswordForm(formUpdate);
    setApiError({ isError: false, message: '' });
  };

  const isPasswordsMatch =
    verifiedNewPassword && forgotPasswordForm.password === forgotPasswordForm.passwordConfirmation;

  const isDisabledSubmit = () => {
    if (changePasswordToken?.length) {
      return !isPasswordsMatch || apiError.message;
    }

    return !isEmailSubmitAllowed;
  };

  /**
   * Confirmation of the new password field needs additional checks for equality to new password
   */
  const isPasswordConfirmationValid = useCallback(() => {
    return (
      forgotPasswordForm.passwordConfirmation?.length >= 12 &&
      forgotPasswordForm.passwordConfirmation?.length >= forgotPasswordForm.password?.length
    );
  }, [forgotPasswordForm.passwordConfirmation?.length, forgotPasswordForm.password?.length]);

  useEffect(() => {
    if (verifiedNewPassword && isPasswordConfirmationValid()) setIsPasswordConfirmed(isPasswordsMatch);
  }, [forgotPasswordForm.passwordConfirmation, isPasswordConfirmationValid, isPasswordsMatch, verifiedNewPassword]);

  /**
   * If localStorage 'expiredCode' variable is equal to changePasswordToken - show success screen
   */ 
  let isChangePasswordTokenExpired = false;

  if (typeof localStorage.getItem('expiredCode') === 'string' && typeof changePasswordToken === 'string') {
    isChangePasswordTokenExpired = localStorage.getItem('expiredCode') === changePasswordToken;
  }

  if (isNewPasswordSent || isChangePasswordTokenExpired) {
    return (
      <>
        <h1 className="text-main-black text-xxl text-center mt-[40%]">Success!</h1>

        <div className="w-full flex flex-col gap-[48px] mt-[48px]">
          <div className="flex flex-col gap-[48px] items-center">
            <img alt="checkmark" src={checkmarkImage} className="h-[100px]" />
            <p className="max-w-[400px] text-center">Your password has been successfully reset.</p>
          </div>
        </div>

        <CulturlabsButton
          type="button"
          className="mt-[48px] w-2/3 mx-auto text-nowrap"
          onClick={() => navigate('/login')}
        >
          Return to Login
        </CulturlabsButton>
      </>
    );
  }

  return (
    <>
      <h1 className="text-main-black text-xxl mb-[16px]">
        {!changePasswordToken?.length ? 'Forgot password?' : 'Reset Password'}
      </h1>

      {!changePasswordToken?.length ? (
        <>
          <p className="font-inter text-l mb-[24px]">
            Let us help you. Enter your email below to receive a link to reset your password.
          </p>

          <p className="font-inter text-l mb-[24px]">
            Back to <CulturlabsLink href="/login" className="text-l" label="Login" />
          </p>

          <CulturlabsInput
            label="email"
            placeholderText="your@email.com"
            className="mt-[24px]"
            invalid={!isEmailValid}
            onBlur={() => setIsEmailFieldTouched(true)}
            handleChange={changeEvent => handleInputChange('email', changeEvent)}
            onKeyDown={(changeEvent) => rejectInsertedSpace(changeEvent)}
          />

          <p
            className={classNames('text-red-validation-message text-m italic mb-[6px]', {
              invisible: isEmailValid,
            })}
          >
            Please enter a valid email address.
          </p>
        </>
      ) : (
        <>
          <CulturlabsInput
            inputType="password"
            label="New password"
            placeholderText="Enter new password"
            className="mt-[16px] mb-[16px]"
            invalid={apiError.isError}
            autocomplete="off"
            handleChange={changeEvent => handleInputChange('password', changeEvent)}
            onKeyDown={(changeEvent) => rejectInsertedSpace(changeEvent)}
          />

          <NewPasswordValidation
            password={forgotPasswordForm.password}
            passwordConfirmation={forgotPasswordForm.passwordConfirmation}
          />

          <CulturlabsInput
            inputType="password"
            label="Password confirmation"
            placeholderText="Enter password conformation"
            className="mt-[24px]"
            invalid={!isPasswordConfirmed || apiError.isError}
            autocomplete="off"
            handleChange={changeEvent => handleInputChange('passwordConfirmation', changeEvent)}
            onKeyDown={(changeEvent) => rejectInsertedSpace(changeEvent)}
          />

          <p
            className={classNames('text-red-validation-message text-m italic mb-[24px]', {
              invisible: isPasswordConfirmed,
            })}
          >
            Passwords are not matching.
          </p>
        </>
      )}

      <CulturlabsButton disabled={isDisabledSubmit()} type="submit" className="mt-[24px] w-full" onClick={onSubmit}>
        {!changePasswordToken?.length ? 'Send Reset Link' : 'Reset Password'}
      </CulturlabsButton>

      <p className="text-red-validation-message text-m italic mt-[6px]">{apiError.message}</p>
    </>
  );
};

export default ForgotPasswordPage;

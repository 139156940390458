import NavTab from '../atoms/NavTab/NavTab';

const NavTabsTemperature = ({ className }) => {
  return (
    <nav className={`flex flex-row ${className}`}>
      <NavTab className="mb-[8px] px-[20px]" path={'/index/temperature/overview'}>
        <span>Overview</span>
      </NavTab>
      <NavTab className="px-[20px] py-[8px]" path={'/index/temperature/statistics'}>
        <span>Statistics</span>
      </NavTab>
    </nav>
  );
};

export default NavTabsTemperature;

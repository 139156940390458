import { NavLink } from 'react-router-dom';

const NavigationSidebarButton = ({ path, children, className, onClick }) => {
  return (
    <NavLink to={path} className={({ isActive }) => `${getClasses(isActive)} ${className}`} onClick={onClick}>
      {children}
    </NavLink>
  );
};

export default NavigationSidebarButton;

const getClasses = isActive =>
  `${
    isActive ? getActiveClasses() : getOriginalClasses()
  } text-s text-center font-lato flex flex-col justify-center items-center cursor-pointer min-h-[60px] w-[116px] rounded-[16px]`;

const getActiveClasses = () => 'bg-secondary-violet text-white [&>*]:fill-white';

const getOriginalClasses = () => 'text-white [&>*]:fill-white';

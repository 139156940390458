import classNames from 'classnames';

const getClassNames = (primary, disabled) =>
  `${commonClasses()} ${primary ? getPrimaryClasses(disabled) : getSecondaryClasses()} ${disabled && 'cursor-not-allowed'}`;

const commonClasses = () =>
  'box-border cursor-pointer flex justify-center items-center h-[48px] px-[16px] text-l radius-[24px] rounded-[24px]';

const getPrimaryClasses = disabled =>
  classNames(
    'text-white [&>*]:text-white bg-main-blue hover:bg-blue-01 active:bg-blue-02 disabled:bg-blue-03 disabled:text-gray-disabled disabled:cursor-not-allowed',
    {
      'hover:shadow-button-primary': !disabled,
    },
  );

const getSecondaryClasses = () =>
  'text-blue-01 [&>*]:text-blue-01 bg-white border-solid border border-blue-01 active:border-blue-02 hover:shadow-button-primary active:[&>*]:text-blue-02 active:text-blue-02 disabled:cursor-not-allowed disabled:border-blue-04 disabled:[&>*]:text-blue-04 disabled:text-blue-04';

const CulturlabsButton = ({ children, className, style, onClick, secondary, primary, disabled }) => (
  <button
    disabled={disabled}
    className={classNames(getClassNames(primary || !secondary, disabled), className)}
    style={style}
    onClick={onClick}
  >
    {children}
  </button>
);

export default CulturlabsButton;

/**
 *  Check if value selected from select component
 * @param value
 * @returns {boolean}
 */
const isSelected = value => {
  return value && value !== '-';
};

export default isSelected;

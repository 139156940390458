import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useCurrentUser from '../../hooks/useCurrentUser';
import useQuery from '../../hooks/useQuery';
import AuthService, { setDefaultOrganizationRoleIfMissed } from '../../services/Auth.service';
import useAppStore from '../../store';

const ENV_HASH_ORGANIZATIONS = ['unilever'];

const getOrganizationHash = (organization, query) => {
  const providedHash = query.get('hash');
  if (providedHash || !ENV_HASH_ORGANIZATIONS.includes(organization)) return providedHash;

  return query.get('surveyType') === 'pulse'
    ? process.env[`REACT_APP_${organization.toUpperCase()}_ORGANIZATION_PULSE_HASH`]
    : process.env[`REACT_APP_${organization.toUpperCase()}_ORGANIZATION_BASELINE_HASH`];
};

export const HashAuthorization = () => {
  const { organization } = useParams();
  const navigate = useNavigate();
  const query = useQuery();
  const setAuth = useAppStore(state => state.setAuth);
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    if (currentUser) logout();
    handleHashLogin();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('aboutToLogoutNotificationTimer');
    localStorage.removeItem('me');
    localStorage.removeItem('logoutTimer');
    setAuth({});
  };

  const handleHashLogin = async () => {
    const response = await AuthService.authenticateWithHash({
      hash: getOrganizationHash(organization, query),
      organization,
    }).catch(() => navigate('/404'));
    if (response?.status === 200) {
      const { user } = response?.data;

      AuthService.handleAuthResponse({ ...response?.data, user: setDefaultOrganizationRoleIfMissed(user) });
    }

    navigate('/index/survey');
  };

  return <div>Authorization...</div>;
};

import { saveAs } from 'file-saver';
import React, { Fragment, useEffect, useState } from 'react';

import CulturlabsButton from '../../components/atoms/CulturlabsButton/CulturlabsButton';
import getAuthHeader from '../../helpers/getAuthHeader';
import APIService from '../../services/API.service';

const HEADER_TEXT = 'Export Results';

export const ExportResults = () => {
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchSurveys();
  }, []);

  const fetchSurveys = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await APIService.call({
        method: 'get',
        url: APIService.urls.surveys,
        params: {
          withNumberOfParticipants: true,
        },
        headers: getAuthHeader(),
      });

      setSurveys(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const exportSurveyInXLSX = async surveyId => {
    try {
      setLoading(true);
      const { data } = await APIService.call({
        method: 'post',
        data: {
          surveyId,
        },
        url: APIService.urls.exportPassedSurveys,
        headers: getAuthHeader(),
        responseType: 'blob',
      });

      saveXLSXFile(data, `passed-survey-${surveyId}.xlsx`);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const saveXLSXFile = (blob, fileName = 'file.xlsx') =>
    saveAs(new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), fileName);

  const getRow = survey => (
    <div className="w-full py-[12px] flex justify-between">
      <div className="flex gap-8">
        <div className="uppercase w-24 flex items-center">{survey.id}</div>
        <div className="uppercase w-48 flex items-center">{survey.numberOfParticipants || '-'}</div>
      </div>
      <div className="w-24">
        <CulturlabsButton
          disabled={loading}
          className="mx-auto w-24 flex items-center justify-center"
          onClick={() => exportSurveyInXLSX(survey.id)}
        >
          <span>Export</span>
        </CulturlabsButton>
      </div>
    </div>
  );

  return (
    <div className="h-screen w-full xl:min-w-[630px] p-[24px] md:p-[48px] flex flex-col items-center overflow-y-scroll">
      <h1 className="text-lato text-main-black text-xl md:text-xxl font-bold mb-[32px]">{HEADER_TEXT}</h1>

      <div className="bg-white w-full shrink rounded-[24px] flex flex-col items-center">
        <div className="w-full bg-table-blue [&>*]:font-[600] [&>*]:font-lato [&>*]:text-m flex justify-between p-[20px] md:px-[40px] py-[12px] rounded-t-[24px]">
          <div className="flex gap-8">
            <div className="uppercase w-24">Survey ID</div>
            <div className="uppercase w-48">number of records</div>
          </div>
          <div className="w-24" />
        </div>
        <div className="w-full px-[20px] md:px-[40px] divide-y">
          {loading && <div className="w-full py-[12px] flex justify-center">Loading...</div>}
          {!loading && !surveys.length && <div className="w-full py-[12px] flex justify-center">No surveys found</div>}
          {surveys.map(survey => (
            <Fragment key={survey.id}>{getRow(survey)}</Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

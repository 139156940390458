import classNames from 'classnames';
import { useEffect, useState } from 'react';

import emptyArrowFunc from '../../../helpers/emptyArrowFunc.helper';
import unwrapValueFromInputChangeEvent from '../../../helpers/unwrapValueFromInputChangeEvent.helper';
import './AnswerRangeSlider.css';

const AnswerRangeSlider = ({ isTouched, onChange = emptyArrowFunc, onTouch = emptyArrowFunc, initValue }) => {
  const [value, setValue] = useState(initValue);

  useEffect(() => {
    setValue(initValue || '');
  }, [initValue]);

  return (
    <div className={classNames('answer-range-slider-wrapper', { touched: isTouched })}>
      <input
        type="range"
        value={value}
        onClick={() => onTouch(true)}
        onChange={event => {
          onChange(event);
          setValue(unwrapValueFromInputChangeEvent(event));
        }}
      />
    </div>
  );
};

export default AnswerRangeSlider;

import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import APIService from '../../services/API.service';
import useAppStore from '../../store';

const callOrgItems = (url, token) => {
  const bearer = localStorage.getItem('token');
  const config = {
    url,
  };

  if (bearer?.length)
    config.headers = {
      Authorization: `Bearer ${bearer}`,
    };

  let method;
  if (token) {
    config.data = { token };
    method = token ? 'post' : 'get';
  }
  config.method = method;

  return APIService.call(config)
    .catch(emptyArrowFunc)
    .then(response => {
      return response?.data?.data || response?.data;
    });
};

export default callOrgItems;

import React, { memo } from 'react';

const Loading = ({ loading, children, loadingMessage }) => {
  if (loading) {
    return (
      <div className="z-[8000]">
        <div role="status" aria-live="polite" className="bg-white fixed inset-0 opacity-20" />
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-16 py-8 flex flex-col justify-center items-center gap-4">
          <span className="w-12 h-12 border-4 border-black border-b-transparent rounded-full inline-block box-border animate-spin" />
          {loadingMessage && <span className="font-bold">{loadingMessage}</span>}
        </div>
      </div>
    );
  }

  return children;
};

export default memo(Loading);

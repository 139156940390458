import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';

import getAuthHeader from '../../helpers/getAuthHeader';
import APIService from '../../services/API.service';

const HEADER_TEXT = 'List of employees';
const PAGE_SIZE = 50;

export const EmployeeList = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    fetchEmployees();
  }, [page]);

  const getSurveyTypeName = surveyType => {
    const map = { full: 'baseline' };
    return map[surveyType] ?? surveyType;
  };

  const fetchEmployees = async () => {
    try {
      setLoading(true);
      const {
        data: { data, meta },
      } = await APIService.call({
        method: 'get',
        url: APIService.urls.employees,
        params: {
          withSurveyStatus: true,
          pagination: {
            pageSize: PAGE_SIZE,
            page,
          },
        },
        headers: getAuthHeader(),
      });

      setEmployees(data);
      setPagination(meta.pagination);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getHeader = () => (
    <div className="w-full py-[12px] flex justify-between [&>*]:font-[600]">
      <div className="w-24 max-w-24 break-all flex items-center text-s">{'Employee Name'}</div>
      <div className="w-40 max-w-40 break-all flex items-center text-s">{'Email'}</div>
      <div className="w-32 max-w-32 break-all flex items-center text-s">{'Team'}</div>
      <div className="w-32 max-w-32 break-all flex items-center text-s">{'Department'}</div>
      <div className="w-32 max-w-32 break-all flex items-center text-s">{'Survey Assigned'}</div>
      <div className="w-32 max-w-32 break-all flex items-center text-s">{'Completion Status'}</div>
    </div>
  );

  const getRow = employee => {
    let surveyStatus = '-';
    if (employee.survey) {
      surveyStatus = employee.survey.completed ? (
        <span className="text-green-success">Completed</span>
      ) : (
        <span className="text-red-failure">Not Completed</span>
      );
    }

    return (
      <div className="w-full py-[12px] flex justify-between">
        <div className="w-24 max-w-24 break-all flex items-center text-s">{employee.name || '-'}</div>
        <div className="w-40 max-w-40 break-all flex items-center text-s">{employee.email || '-'}</div>
        <div className="w-32 max-w-32 break-all flex items-center text-s">{employee.team?.name || '-'}</div>
        <div className="w-32 max-w-32 break-all flex items-center text-s">{employee.department?.name || '-'}</div>
        <div className="w-32 max-w-32 break-all flex items-center text-s capitalize">
          {getSurveyTypeName(employee.survey?.type || '-')}
        </div>
        <div className="w-32 max-w-32 break-all flex items-center text-s">{surveyStatus}</div>
      </div>
    );
  };

  return (
    <div className="h-screen w-full xl:min-w-[630px] p-[24px] md:p-[48px] flex flex-col items-center overflow-y-scroll">
      <div className="bg-white w-full shrink rounded-[24px] flex flex-col items-center">
        <div className="w-full bg-table-blue [&>*]:font-[600] [&>*]:font-lato [&>*]:text-l flex justify-between p-[20px] md:px-[40px] py-[12px] rounded-t-[24px]">
          <div>{HEADER_TEXT}</div>
        </div>
        <div className="w-full px-[20px] md:px-[40px] divide-y divide-gray-03">
          {loading && <div className="w-full py-[12px] flex justify-center">Loading...</div>}
          {!loading && !employees.length && (
            <div className="w-full py-[12px] flex justify-center">No employees found</div>
          )}
          {!loading && (
            <>
              {!!employees.length && getHeader()}
              {employees.map(employee => (
                <Fragment key={employee.id}>{getRow(employee)}</Fragment>
              ))}
            </>
          )}
        </div>
        {!loading && !!employees.length && (
          <div className="m-[16px] flex flex-wrap justify-center gap-4 max-w-full">
            {new Array(pagination.pageCount).fill(true).map((_, index) => (
              <div
                key={index}
                className={classNames('rounded-full px-2 cursor-pointer duration-100', {
                  'bg-blue-03 text-white': index + 1 === page,
                  'hover:text-blue-03': index + 1 !== page,
                })}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

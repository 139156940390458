import classNames from 'classnames';
import React, { useEffect, useImperativeHandle, useState } from 'react';

import { ReactComponent as DownSVG } from '../../../assets/arrow-down.svg';

const CulturlabsSelect = React.forwardRef(
  ({ className, label, onChange, disabled, listItems, initValue, idMapper, labelMapper, required = false }, ref) => {
    // State to keep track of the selected value
    const [selectedValue, setSelectedValue] = useState(initValue);

    // Effect to update selectedValue if initValue changes
    useEffect(() => {
      setSelectedValue(initValue);
    }, [initValue]);

    useImperativeHandle(
      ref,
      () => {
        return {
          clearValue: () => {
            setSelectedValue({});
          },
        };
      },
      [],
    );

    // Handler for when the selection changes
    const handleSelectChange = changeEvent => {
      const newValue = changeEvent.target.value === '-' ? undefined : changeEvent.target.value;
      setSelectedValue(newValue); // Update the state with the new value
      onChange(label, changeEvent); // You might need to adjust how onChange is called based on your handler
    };

    const listOfOptions = listItems?.map(listItem => (
      <option key={idMapper(listItem)} value={idMapper(listItem)}>
        {labelMapper(listItem)}
      </option>
    ));

    return (
      <div className={classNames('flex flex-col', className)}>
        <label
          htmlFor={label} // Changed from 'for' to 'htmlFor'
          className="text-main-black font-semibold mb-[8px] text-s uppercase font-inter"
        >
          {label}
          {required && <span className="text-red text-[16px]">*</span>}
        </label>
        <div className="relative">
          <DownSVG className="pointer-events-none absolute top-[50%] right-[10px] translate-y-[-50%]" />
          <select
            disabled={disabled}
            id={label}
            value={selectedValue} // Controlled by selectedValue state
            className={classNames(
              'bg-white w-full text-l font-inter placeholder:text-l rounded-[8px] outline-none border-solid border-gray-01 hover:border-blue-01 disabled:hover:border-gray-01 focus:border-blue-01 focus:shadow-input border h-[48px] indent-[16px] appearance-none',
              {
                'text-main-black': selectedValue,
                'text-gray-01': !selectedValue,
              },
            )}
            onChange={handleSelectChange}
          >
            <option value="-">Choose {label}</option>
            {listOfOptions}
          </select>
        </div>
      </div>
    );
  },
);

export default CulturlabsSelect;

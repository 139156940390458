import ReactApexChart from 'react-apexcharts';

import CulturlabsButton from '../../components/atoms/CulturlabsButton/CulturlabsButton';

const QuadrantChartSurveyResultPage = ({ answers = [], onClose }) => {
  const calculatedAnswers = answers.reduce((reducer, answer, index, array) => {
    const factor = answer?.factor?.label;
    if (!reducer[factor]?.length) reducer[factor] = [];

    reducer[factor].push(Number.parseInt(answer.answer_value));

    if (index + 1 === array.length)
      Object.keys(reducer).forEach(key => {
        reducer[key] = reducer[key].reduce((sum, value) => sum + Number.parseInt(value), 0) / reducer[key].length;
      });

    return reducer;
  }, {});

  const series = [
    {
      data: Object.values(calculatedAnswers),
    },
  ];

  const labels = {
    show: true,
    style: {
      colors: ['#3C3C3B', '#3C3C3B', '#3C3C3B', '#3C3C3B'],
      fontSize: '18px',
    },
  };

  const options = {
    xaxis: {
      labels: labels,
      min: 0,
      max: 100,
      categories: Object.keys(calculatedAnswers).map(factor =>
        factor == 'Social Environment' ? ['Social', 'Environment'] : factor,
      ),
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    colors: ['#3C3C3B'],
  };

  return (
    <div className="w-full h-[calc(100vh-5px)] p-[48px]">
      <div className="flex flex-col justify-center w-full">
        <h1 className="align-flex-start text-lato text-main-black text-xxl font-bold mb-[32px]">Your temperature</h1>
        <CulturlabsButton type="button" className="w-[100px]" onClick={() => onClose()}>
          Finish
        </CulturlabsButton>
        <div className="w-full flex flex-row justify-center items-center w-[900px] h-[600px]">
          <div className="bg-gradient-radial absolute mb-[30px] mr-[40px] w-[400px] h-[400px] rotate-45"></div>
          <div className="flex flex-row items-center justify-center">
            <ReactApexChart options={options} series={series} type="radar" height={600} width={900} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuadrantChartSurveyResultPage;

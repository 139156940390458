import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Highlights specified substrings in the provided text.
 *
 * @param {string} text - The text to be highlighted.
 * @param {string[]} highlights - An array of substrings to highlight.
 * @returns {React.Element} A React element with highlighted text.
 */
const getHighlightedText = (text, highlights) => {
  // Validate inputs
  if (typeof text !== 'string' || !Array.isArray(highlights) || highlights.length === 0) {
    return <span>{text}</span>;
  }

  // Create a regex pattern to match any of the highlight strings
  const escapeRegex = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const pattern = highlights.map(escapeRegex).join('|');
  const regex = new RegExp(`(${pattern})`, 'gi');

  // Split the text based on the regex
  const parts = text.split(regex);

  return (
    <span>
      {parts.map((part, index) => {
        // Determine if the current part should be highlighted
        const isHighlighted = highlights.some(highlight => part.toLowerCase() === highlight.toLowerCase());

        return (
          <span key={index} className={classNames({ 'font-bold': isHighlighted })}>
            {part}
          </span>
        );
      })}
    </span>
  );
};

// Define prop types for validation
getHighlightedText.propTypes = {
  text: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string),
};

export default getHighlightedText;

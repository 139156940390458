import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import { ReactComponent as CloseSVG } from '../../assets/close.svg';
import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import unwrapValueFromInputChangeEvent from '../../helpers/unwrapValueFromInputChangeEvent.helper';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';
import CulturlabsInput from '../atoms/CulturlabsInput/CulturlabsInput';

const PasswordChangeModal = ({ onApply, closeModal = emptyArrowFunc }) => {
  const [controlsState, setControlsState] = useState({
    password: '',
    newPassword: '',
    repeatPassword: '',
  });
  const [isPasswordChangeFormTouched, setIsPasswordChangeFormTouched] = useState(false);
  const [serverError, setServerError] = useState('');

  useEffect(() => resetServerError(), [controlsState.password]);

  const validatePasswords = () => {
    if (controlsState.password.length < 6) {
      return 'The existing password must be at least 6 characters long.';
    } else if (controlsState.newPassword.length < 6) {
      return 'The new password must be at least 6 characters long.';
    } else if (controlsState.newPassword !== controlsState.repeatPassword) {
      return 'Passwords do not match.';
    } else if (controlsState.password === controlsState.newPassword) {
      return 'The new password should not match the existing one.';
    }
    return '';
  };

  const validationError = useMemo(() => validatePasswords() || serverError, [controlsState, serverError]);

  const resetServerError = () => setServerError('');

  const handleBlur = () => setIsPasswordChangeFormTouched(true);

  const handleChange = (formControlName, originalEvent) => {
    setIsPasswordChangeFormTouched(true);
    const value = unwrapValueFromInputChangeEvent(originalEvent) || '';
    setControlsState(prevState => ({
      ...prevState,
      [formControlName]: value.trim(),
    }));
  };

  const handleApply = async () => {
    const error = await onApply({
      password: controlsState['password'],
      newPassword: controlsState['newPassword'],
    });
    if (error?.response.data.error.message) {
      setServerError(error.response.data.error.message);
      return;
    }

    resetServerError();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black opacity-50" onClick={closeModal} />
      <div className="absolute flex flex-col w-[400px] h-[500px] rounded-2xl p-8 bg-white shadow-lg">
        <header className="flex justify-between items-center mb-8">
          <h2 className="text-xl font-bold">Update Password</h2>
          <CloseSVG className="fill-current text-black w-6 h-6 cursor-pointer" onClick={closeModal} />
        </header>
        <div className="flex flex-col items-center">
          <CulturlabsInput
            label="Existing Password"
            inputType="password"
            className="mb-4 w-full"
            onBlur={handleBlur}
            handleChange={e => handleChange('password', e)}
          />
          <CulturlabsInput
            label="New Password"
            inputType="password"
            className="mb-4 w-full"
            onBlur={handleBlur}
            handleChange={e => handleChange('newPassword', e)}
          />
          <CulturlabsInput
            label="Repeat New Password"
            inputType="password"
            className="mb-4 w-full"
            onBlur={handleBlur}
            handleChange={e => handleChange('repeatPassword', e)}
          />
          {validationError && isPasswordChangeFormTouched && (
            <p className="text-red-validation-message text-m italic mb-4 w-full">{validationError}</p>
          )}
        </div>
        <CulturlabsButton
          type="button"
          className={classNames(
            'mt-auto',
            { 'bg-blue-300': !!validationError, 'bg-blue-500 hover:bg-blue-600': !validationError },
            'text-white w-full py-2 rounded-md',
          )}
          onClick={handleApply}
          disabled={!!validationError}
        >
          Apply
        </CulturlabsButton>
      </div>
    </div>
  );
};

export default PasswordChangeModal;

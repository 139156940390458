import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import emptyArrowFunc from '../../../helpers/emptyArrowFunc.helper';
import './CulturlabsCheckBox.css';

const CulturlabsCheckbox = ({ children, label, inputID, incomingValue, className, onChange = emptyArrowFunc }) => {
  const controlId = inputID || uuidv4();
  const [value, setValue] = useState(incomingValue);

  useEffect(() => {
    setValue(incomingValue);
  }, [incomingValue]);

  return (
    <div className={`culturlabsCheckbox flex flex-row ${className}`}>
      <input
        id={controlId}
        checked={value}
        className="hidden w-[0px] cursor-pointer"
        type="checkbox"
        onClick={event => onChange(event.target.checked)}
      ></input>
      <label for={controlId} className="text-m indent-[30px] cursor-pointer" style={{ whiteSpace: 'pre-wrap' }}>
        {label}
      </label>
      {children}
    </div>
  );
};

export default CulturlabsCheckbox;

import useAppStore from './../store';
import APIService from './API.service';

export const setDefaultOrganizationRoleIfMissed = user =>
  user.organization && !user.organizationRole
    ? { ...user, organizationRole: process.env.REACT_APP_DEFAULT_USER_ORGANIZATION_ROLE }
    : user;

const AuthService = () => {
  return {
    authenticate: async data => {
      const { urls } = APIService;
      const callConfig = {
        url: urls.auth,
        method: 'post',
        data,
      };

      return await APIService.call(callConfig);
    },
    authenticateWithHash: async ({ hash, organization }) => {
      const { urls } = APIService;
      const callConfig = {
        url: urls.auth,
        method: 'post',
        headers: {
          'x-authorization-type': 'hash',
        },
        data: {
          identifier: hash,
          organization,
        },
      };

      return await APIService.call(callConfig);
    },
    handleAuthResponse: ({ jwt, user }) => {
      useAppStore.getState().setMe(user);
      useAppStore.getState().setAuth({ jwt });
      localStorage.setItem('token', jwt);
      localStorage.setItem('me', JSON.stringify(user));
    },
    getTokenFromLocalStorage: () => {
      const localStorageToken = localStorage.getItem('token');
      useAppStore.getState().setAuth({ jwt: localStorageToken });
    },
    logout: () => {
      localStorage.removeItem('token');
      localStorage.removeItem('aboutToLogoutNotificationTimer');
      localStorage.removeItem('me');
      localStorage.removeItem('logoutTimer');
      useAppStore.getState().setAuth({});
    },
  };
};

export default AuthService();

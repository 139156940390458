import * as RadioGroup from '@radix-ui/react-radio-group';
import { v4 as uuidv4 } from 'uuid';

import './CulturlabsRatingControl.css';

const CulturlabsRatingControl = ({ className, onControlChange }) => {
  const controlId = uuidv4();

  return (
    <RadioGroup.Root
      className={`RadioGroupRoot ${className}`}
      defaultValue="default"
      aria-label="View density"
      onValueChange={value => onControlChange(value)}
    >
      <RadioGroup.Item className="RadioGroupItem" value="1" id={`control-1-${controlId}`}>
        <RadioGroup.Indicator className="RadioGroupIndicator" />
      </RadioGroup.Item>
      <RadioGroup.Item className="RadioGroupItem" value="2" id={`control-2-${controlId}`}>
        <RadioGroup.Indicator className="RadioGroupIndicator" />
      </RadioGroup.Item>
      <RadioGroup.Item className="RadioGroupItem" value="3" id={`control-3-${controlId}`}>
        <RadioGroup.Indicator className="RadioGroupIndicator" />
      </RadioGroup.Item>
      <RadioGroup.Item className="RadioGroupItem" value="4" id={`control-4-${controlId}`}>
        <RadioGroup.Indicator className="RadioGroupIndicator" />
      </RadioGroup.Item>
      <RadioGroup.Item className="RadioGroupItem" value="5" id={`control-5-${controlId}`}>
        <RadioGroup.Indicator className="RadioGroupIndicator" />
      </RadioGroup.Item>
      <RadioGroup.Item className="RadioGroupItem" value="6" id={`control-6-${controlId}`}>
        <RadioGroup.Indicator className="RadioGroupIndicator" />
      </RadioGroup.Item>
      <RadioGroup.Item className="RadioGroupItem" value="7" id={`control-7-${controlId}`}>
        <RadioGroup.Indicator className="RadioGroupIndicator" />
      </RadioGroup.Item>
      <RadioGroup.Item className="RadioGroupItem" value="8" id={`control-8-${controlId}`}>
        <RadioGroup.Indicator className="RadioGroupIndicator" />
      </RadioGroup.Item>
      <RadioGroup.Item className="RadioGroupItem" value="9" id={`control-9-${controlId}`}>
        <RadioGroup.Indicator className="RadioGroupIndicator" />
      </RadioGroup.Item>
    </RadioGroup.Root>
  );
};

export default CulturlabsRatingControl;

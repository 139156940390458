import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import './App.css';
import CulturlabsButton from './components/atoms/CulturlabsButton/CulturlabsButton';
import './fonts.css';
import useCurrentUser from './hooks/useCurrentUser';
import Router from './router/Router';
import AuthService from './services/Auth.service';
import useAppStore from './store';

function App() {
  const setAuth = useAppStore(state => state.setAuth);
  const [isLogoutWarningShown, setLogoutWarningShown] = useState(false);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const tokenExists = !!localStorage.getItem('token');
  const { isCorporative } = useCurrentUser();

  const logoutTime = Number.parseInt(process.env.REACT_APP_LOGOUT_TIMER_DELAY_MS, 10);
  const logoutWarningTime = Number.parseInt(process.env.REACT_APP_LOGOUT_WARNING_TIMER_DELAY_MS, 10);

  const logout = () => {
    setAuth({});
    setLogoutWarningShown(false);
    localStorage.removeItem('token');
    localStorage.removeItem('aboutToLogoutNotificationTimer');
    localStorage.removeItem('me');
    localStorage.removeItem('logoutTimer');
  };

  const updateLastActivityTime = () => {
    setLastActivityTime(Date.now());
  };

  useEffect(() => {
    AuthService.getTokenFromLocalStorage();
    if (isCorporative) return;

    const checkActivity = () => {
      const now = Date.now();
      const inactiveTime = now - lastActivityTime;

      if (inactiveTime >= logoutTime) {
        logout();
      } else if (inactiveTime >= logoutWarningTime && !isLogoutWarningShown) {
        setLogoutWarningShown(true);
      }
    };

    const activityInterval = setInterval(checkActivity, 5000);

    const handleUserActivity = () => {
      updateLastActivityTime();
    };

    window.addEventListener('click', handleUserActivity);
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);

    return () => {
      clearInterval(activityInterval);
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
    };
  }, [isLogoutWarningShown, lastActivityTime, isCorporative]);

  const hideLogoutWarning = () => {
    setLogoutWarningShown(false);
    updateLastActivityTime();
  };

  return (
    <div className="App relative bg-lightgray">
      <BrowserRouter>
        <Router />
      </BrowserRouter>

      {isLogoutWarningShown && tokenExists && (
        <div className="absolute bottom-0 right-0 p-[24px] flex flex-col items-center justify-center bg-white border border-main-black w-[500px] h-[200px] rounded-[24px]">
          <p className="text-main-black text-l text-center">
            You will be automatically logged out in case of inactivity. Are you still here?
          </p>
          <CulturlabsButton
            className="mt-[24px] flex flex-row mb-[16px] justify-start px-5 items-center bg-gray rounded-2 h-[30px] w-[250px] rounded-[10px]"
            onClick={hideLogoutWarning}
          >
            Yes, I'm here!
          </CulturlabsButton>
        </div>
      )}
    </div>
  );
}

export default App;
